import type { Options } from '#netzo/shared/types/core'
import { createId, createUid } from '#netzo/utils/core/db'
import { addDays, format, parseISO } from 'date-fns'
import { merge } from 'es-toolkit/compat'

export const getDefaultQuote = (data: Partial<Quote>) => {
  const uid = createUid(new Date(), 'COT') // IMPORTANT: cloudflare throws error if called on global scope
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'totalized',
    status: 'draft',
    uid: uid,
    name: uid,
    receiver: {
      taxCountryCode: 'MX',
      address: {},
    },
    issuer: {
      taxId: useAppConfig().netzo.defaults.legal.taxId,
      taxCountryCode: useAppConfig().netzo.defaults.legal.taxCountryCode,
      legalName: useAppConfig().netzo.defaults.legal.legalName,
      satFiscalRegime: useAppConfig().netzo.defaults.legal.satFiscalRegime,
      phone: useAppConfig().netzo.defaults.legal.phone,
      email: useAppConfig().netzo.defaults.legal.email,
      issuedIn: useAppConfig().netzo.defaults.legal.address.postalCode + ' ' + useAppConfig().netzo.defaults.legal.address.state,
      cfdiType: 'I',
      address: useAppConfig().netzo.defaults.legal.address,
    },
    message: {
      enabled: true,
      subject: 'Estimado cliente,',
      body: 'En seguimiento a su solicitud de nuestros productos y servicios, presentamos nuestra cotización con las características que se encontrarán a continuación. Agradecemos su tiempo e interés. Por favor, cualquier duda y/o aclaración, háganoslo saber.',
    },
    isDatetime: false,
    issueDate: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    expirationDate: new Date(`${format(addDays(new Date(), 14), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    delivery: {
      incoterm: 'pending',
      schedule: 'pending',
      isDatetime: false,
      useAccountAddress: true,
      address: {},
    },
    payment: {
      exchangeRate: {
        value: 1,
        date: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
      },
    },
    paymentAccounts: [
      {
        "id": "4es6w1a7973s",
        "name": "Banregio",
        "currency": "MXN",
        "clabe": "058580220507700139",
        "accountNumber": "022050770013"
      }
    ],
    notes: [],
    filePdf: {},
    pdfSettings: useAppConfig().netzo.defaults.pdfSettings,
    tags: [],
    data: getDefaultQuoteData(data?.data ?? {}),
  }, data)
}

export const getQuoteInformation = (data: Partial<QuoteWithRelations>) => {
  if (!data) return []

  const maps = {
    type: toMapByKey(optionsQuotes.type, 'value'),
    status: toMapByKey(optionsQuotes.status, 'value'),
    schedule: toMapByKey(optionsDeliveries.schedule, 'value'),
    incoterm: toMapByKey(optionsDeliveries.incoterm, 'value'),
  }

  const deliverySchedule = data.delivery?.schedule
  const deliveryDate = data.delivery?.date
  const isDatetime = data.delivery?.isDatetime
  const delivery = deliverySchedule !== 'scheduled'
    ? maps.schedule.get(deliverySchedule)?.label
    : deliveryDate
      ? !isDatetime
          ? parseISO(deliveryDate).toLocaleDateString()
          : parseISO(deliveryDate).toLocaleString()
      : null

  return [
    ['Referencia', data.uid],
    ['Nombre', data.name],
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Estado', maps.status.get(data.status!)?.label],
    ['Responsable', data.user?.name],
    ['Cliente', data.account?.name],
    ['Terminos de entrega', maps.incoterm.get(data.delivery?.incoterm)?.label],
    ['Entrega', delivery],
    ['Sucursal', data.branch?.name],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsQuotes = {
  type: [
    { value: 'totalized', label: 'Estándar', description: 'La cotización muestra la suma total de todos los conceptos.', color: 'blue' },
    { value: 'itemized', label: 'Lista de precios', description: 'La cotización solamente muestra los precios de cada concepto.', color: 'green' },
  ], // CANT CHANGE
  status: [
    { value: 'draft', label: 'Borrador', description: 'La cotización está en proceso de creación y no esta finalizada.', icon: 'i-mdi-circle-outline', color: 'gray' },
    { value: 'accepted', label: 'Aceptada', description: 'Cotización aceptada por el cliente.', icon: 'i-mdi-thumb-up', color: 'green' },
    { value: 'rejected', label: 'Rechazada', description: 'Cotización rechazada por el cliente.', icon: 'i-mdi-thumb-down', color: 'red' },
    { value: 'canceled', label: 'Cancelada', description: 'Cotización cancelada por cualquier motivo que no sea aceptación o rechazo.', icon: 'i-mdi-close-circle-outline', color: 'red' },
  ],
  delivery: {
    incoterm: optionsDeliveries.incoterm,
  },
} satisfies Options

export const optionsQuotesMap = {
  Tipo: optionsQuotes.type,
  Estado: optionsQuotes.status,
  Entrega: optionsDeliveriesMap,
}

export const viewSettingsQuotes: ViewSettings = {
  tableName: 'quotes',
  typeOptions: optionsShared.views.filter(view => ['grid', 'kanban', 'calendar'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'filePdf',
      label: 'PDF',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'issueDate',
      label: 'Emisión',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'expirationDate',
      label: 'Expiración',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'user.name',
      label: 'Responsable',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'account.name',
      label: 'Cliente',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'contact.name',
      label: 'Contacto de Cotización',
      sortable: true,
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'delivery.incoterm',
      label: 'Incoterm',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'delivery.schedule',
      label: 'Tiempo de entrega',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'branch.name',
      label: 'Sucursal de entrega',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'delivery.address',
      label: 'Dirección de entrega',
      class: 'min-w-[200px]', // for <th>
    },
    {
      key: 'delivery.text',
      label: 'Detalles de entrega',
      class: 'min-w-[300px]',
    },
    {
      key: 'payment.satPaymentMethod',
      label: 'Método de pago',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.conditions',
      label: 'Condiciones de pago',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.currency',
      label: 'Moneda',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'payment.exchangeRate.value',
      label: 'Tipo cambio',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quoteitems.amount',
      label: 'Subtotal',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quoteitems.taxes.transfers',
      label: 'Traslados',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quoteitems.taxes.retentions',
      label: 'Retenciones',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quoteitems.total',
      label: 'Total',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groupBy: 'status',
  groupByOptions: [
    { label: 'Tipo', value: 'type', options: optionsQuotes.type },
    { label: 'Estado', value: 'status', options: optionsQuotes.status },
  ],
}
