<script setup lang="ts">
import type { BlobPutOptions } from '@nuxthub/core'
import { vElementHover } from '@vueuse/components'

const model = defineModel<string[]>({ default: () => [] })

const props = defineProps<{
  tableName: TableName
  recordId: string
  type?: string
  options?: BlobPutOptions
  data?: Partial<File>
}>()

// NOTE: only accept PDFMake-supported types PNG/JPEG
// to avoid issues later on when generating PDFs
const ACCEPT = 'image/png,image/jpeg'
const { tableName, recordId, type = 'images' } = props

const { createRenamedFile, uploadFile, removeFile } = useApiBlob(tableName, recordId)

const id = useId()

const rerenderKeys = ref(new Map<string, number>())

const loading = ref(false)

const onChange = async (files: File[]) => {
  loading.value = true
  if (!files) return

  const filesArray = Array.from(files)

  const uploadedPathnames = await Promise.all(
    filesArray.map(async (file) => {
      const blobObject = await uploadFile(type, file, props.data, props.options)
      const newPathname = `/api/blob/${blobObject.pathname}`
      rerenderKeys.value.set(newPathname, Math.random())
      return newPathname
    }),
  )

  model.value = [...model.value, ...uploadedPathnames]
  document.getElementById(id).value = null
  loading.value = false
}

const isHovered = ref<boolean[]>(model.value.map(() => false))

const onRemove = async (pathname: string) => {
  await removeFile({ pathname: pathname.replace('/api/blob/', '') })
  model.value = model.value.filter(value => value !== pathname)
  rerenderKeys.value.delete(pathname)
  document.getElementById(id).value = null
}
</script>

<template>
  <div class="flex items-center gap-3 flex-wrap">
    <UInput
      :id="id"
      v-bind="$attrs"
      type="file"
      multiple
      :accept="ACCEPT"
      placeholder="Cargar imágenes"
      class="w-full"
      @change="onChange"
    >
      <UProgress
        v-if="loading"
        animation="carousel"
        size="sm"
        class="px-1"
      />
      <div v-if="model?.length" class="w-full flex flex-wrap gap-2 items-center mt-2">
        <div
          v-for="(pathname, i) in model"
          :key="`${pathname}-${rerenderKeys.get(pathname) || ''}`"
          class="grid gap-2 relative"
          @click="onRemove(pathname)"
        >
          <UAvatar
            :key="rerenderKeys.get(pathname)"
            v-element-hover="(state: boolean) => isHovered[i] = state"
            title="Haz clic para eliminar la imagen"
            size="md"
            :class="{ 'cursor-pointer': isHovered[i] }"
            v-bind="isHovered[i] ? { icon: 'i-mdi-image-remove' } : { src: pathname }"
          />
        </div>
      </div>
    </UInput>
  </div>
</template>
