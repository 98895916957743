import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultBank = (data: Partial<Bank>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    phone: {},
    address: {},
    tags: [],
    data: getDefaultBankData(data?.data ?? {}),
  }, data)
}

export const getBankInformation = (data: Partial<Bank>) => {
  const maps = {
  }
  if (!data) return []
  return [
    ['Nombre', data.name],
    ['Teléfono', toPhoneString(data.phone)],
    ['Pagina web', data.website],
    ['Swift', data.swift],
    ['Código de Banco', data.bankCode],
    ['Número de Filial', data.subsidiaryNumber],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsBanks = {
} satisfies Options

export const optionsBanksMap = {
}

export const relationsBanks: Record<string, boolean> = {
  financialaccounts: true,
  files: true,
  // options: true,
}

export type BankWithRelations = Bank & {
  financialaccounts: Financialaccount[]
  files: File[]
  options: Option[]
}

export const viewSettingsBanks: ViewSettings = {
  tableName: 'banks',
  typeOptions: optionsShared.views.filter(view => ['grid', 'cards'].includes(view.value)),
  type: 'cards',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    // {
    //   key: 'image',
    //   label: 'Imágen',
    //   class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    // },
    {
      key: 'website',
      label: 'Sitio Web',
    },
    {
      key: 'phone',
      label: 'Teléfono',
    },
    {
      key: 'swift',
      label: 'Código SWIFT',
    },
    {
      key: 'bankCode',
      label: 'Código de Banco',
    },
    {
      key: 'subsidiaryNumber',
      label: 'Número de Filial',
    },
    {
      key: 'address',
      label: 'Dirección',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
