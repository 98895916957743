import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultServiceitem = (data: Partial<Serviceitem>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    fiscalData: {},
    tags: [],
    data: getDefaultServiceitemData(data?.data ?? {}),
  }, data)
}

export const serviceitemToConcept = (data: Serviceitem & { service: Service }, basePrice?: number): Partial<Quoteitem> | Partial<Salesorderitem> => {
  const { service, ...serviceitem } = data ?? {}
  return {
    type: 'services',
    subtype: service.type,
    name: serviceitem?.name,
    description: serviceitem?.description || null,
    images: [serviceitem?.image ?? service.image ?? null],
    unit: serviceitem?.unit || null,
    sku: serviceitem?.sku || null,
    fiscalData: {
      satProductServiceKey: service?.fiscalData?.satProductServiceKey ?? undefined,
      satUnitKey: serviceitem?.fiscalData?.satUnitKey ?? undefined,
    },
    unitPrice: basePrice,
    serviceitemId: serviceitem.id,
  }
}

export const getServiceitemInformation = (data: Partial<ServiceitemWithRelations>) => {
  if (!data) return []
  return [
    ['Nombre', data.name],
    ['Descripción', data.description],
    ['Unidad', data.unit],
    ['SKU', data.sku],
    ['Unidad (SAT)', data.fiscalData?.satUnitKey],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsServiceitems = {
} satisfies Options

export const optionsServiceitemsMap = {
  'Datos Fiscales': {
    'Unidad SAT': optionsSAT.productUnitId,
  },
}

export const relationsServiceitems: Record<string, boolean> = {
  pricelistitems: true,
  quoteitems: true,
  salesorderitems: true,
  service: true,
  files: true,
  // options: true,
}

export type ServiceitemWithRelations = Serviceitem & {
  pricelistitems: Pricelistitem[]
  quoteitems: Quoteitem[]
  salesorderitems: Salesorderitem[]
  service: Service
  files: File[]
  options: Option[]
}

export const viewSettingsServiceitems: ViewSettings = {
  tableName: 'serviceitems',
  typeOptions: optionsShared.views.filter(view => ['grid'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'sku',
      label: 'SKU',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'unit',
      label: 'Unidad',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'image',
      label: 'Imágen',
      class: 'min-w-[150px] max-w-[300px] overflow-x-auto',
    },
    {
      key: 'description',
      label: 'Descripción',
      class: 'min-w-[300px]',
    },
    {
      key: 'service.name',
      label: 'Servicio',
      sortable: true,
    },
    {
      key: 'service.type',
      label: 'Tipo de Servicio',
      sortable: true,
      lass: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'service.fiscalData.satProductServiceKey',
      label: 'Clave (SAT)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'fiscalData.satUnitKey',
      label: 'Unidad (SAT)',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
}
