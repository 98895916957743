import type { Options } from '#netzo/shared/types/core'
import type { File, Option } from '#netzo/shared/types/db'
import { createId } from '#netzo/utils/core/db'
import { merge } from 'es-toolkit/compat'

export const getDefaultProductionorderitem = (data: Partial<Productionorderitem>) => {
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'finished-goods',
    status: 'draft',
    priority: '2',
    attributes: {},
    images: [],
    tags: [],
    data: getDefaultProductionorderitemData(data?.data ?? {}),
  }, data)
}

export const optionsProductionorderitems = {
  type: optionsProducts.type,
  status: optionsProductionorders.status,
  priority: optionsShared.priority,
} satisfies Options

export const optionsProductionorderitemsMap = {
  Tipo: optionsProductionorderitems.type,
  Estado: optionsProductionorderitems.status,
  Prioridad: optionsProductionorderitems.priority,
}

export const relationsProductionorderitems: Record<string, boolean> = {
  productitem: true,
  salesorderitem: true,
  user: true,
  productionorder: true,
  files: true,
  // options: true,
}

export type ProductionorderitemWithRelations = Productionorderitem & {
  productitem: Productitem
  salesorderitem: Salesorderitem
  user: User
  productionorder: Productionorder
  files: File[]
  options: Option[]
}

export const viewSettingsProductionorderitems: ViewSettings = {
  tableName: 'productionorderitems',
  typeOptions: optionsShared.views.filter(view => ['grid', 'kanban'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'images',
      label: 'Imágenes',
      class: 'max-w-[300px] overflow-x-auto',
    },
    {
      key: 'name',
      label: 'Nombre',
    },
    {
      key: 'fileName',
      label: 'Nombre de archivo',
    },
    {
      key: 'productitem.name',
      label: 'Item',
      sortable: true,
    },
    {
      key: 'sku',
      label: 'SKU',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quantityPlanned',
      label: 'Cantidad (plan)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'quantityActual',
      label: 'Cantidad (real)',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'unit',
      label: 'Unidad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'attributes.measurements',
      label: 'Medidas',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'text',
      label: 'Especificaciones',
      class: 'min-w-[300px]',
    },
    {
      key: 'user.name',
      label: 'Responsable',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    {
      key: 'productionorder.name',
      label: 'Orden de Producción',
      sortable: true,
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    ...COLUMNS_METADATA,
  ],
  groupBy: 'status',
  groupByOptions: [
    { label: 'Tipo', value: 'type', options: optionsProductionorderitems.type },
    { label: 'Estado', value: 'status', options: optionsProductionorderitems.status },
    { label: 'Prioridad', value: 'priority', options: optionsProductionorderitems.priority },
  ],
}
