import type { Options } from '#netzo/shared/types/core'
import { createId, createUid } from '#netzo/utils/core/db'
import { format, parseISO } from 'date-fns'
import { merge } from 'es-toolkit/compat'

export const getDefaultProductionorder = (data: Partial<Productionorder>) => {
  const uid = createUid(new Date(), 'OP') // IMPORTANT: cloudflare throws error if called on global scope
  return merge({
    id: createId(), // IMPORTANT: cloudflare throws error if called on global scope
    type: 'external',
    status: 'draft',
    priority: '2',
    uid: uid,
    name: uid,
    isDatetime: false,
    dateStart: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    dateEnd: new Date(`${format(new Date(), 'yyyy-MM-dd')}T00:00:00`).toISOString(),
    filePdf: {},
    pdfSettings: {
      ...useAppConfig().netzo.defaults.pdfSettings,
      images: true,
    },
    tags: [],
    data: getDefaultProductionorderData(data?.data ?? {}),
  }, data)
}

export const getProductionorderInformation = (data: Partial<ProductionorderWithRelations>) => {
  if (!data) return []

  const maps = {
    type: toMapByKey(optionsProductionorders.type, 'value'),
    status: toMapByKey(optionsProductionorders.status, 'value'),
    priority: toMapByKey(optionsProductionorders.priority, 'value'),
  }
  const localDateStart = data.dateStart ? parseISO(data.dateStart) : null
  const dateStart = localDateStart
    ? data.isDatetime
      ? localDateStart.toLocaleString()
      : localDateStart.toLocaleDateString()
    : null
  const localDateEnd = data.dateEnd ? parseISO(data.dateEnd) : null
  const dateEnd = localDateEnd
    ? data.isDatetime
      ? localDateEnd.toLocaleString()
      : localDateEnd.toLocaleDateString()
    : null
  return [
    ['Referencia', data.uid],
    ['Nombre', data.name],
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Estado', maps.status.get(data.status!)?.label],
    ['Prioridad', maps.priority.get(data.priority!)?.label],
    ['Fecha de Inicio', dateStart],
    ['Fecha de Cierre', dateEnd],
  ].map(([key, value]) => [key, value || '-'])
}

export const optionsProductionorders = {
  type: [
    { value: 'external', label: 'Externa', description: 'Órdenes originadas de solicitudes de clientes para productos.', icon: 'i-mdi-package-variant', color: 'green' },
    { value: 'internal', label: 'Interna', description: 'Órdenes de trabajo para procesos internos que no están vinculadas a ventas.', icon: 'i-mdi-factory', color: 'blue' },
  ],
  status: [
    { value: 'draft', label: 'Borrador', description: 'Orden en estado de borrador. No se ha iniciado ningún proceso.', icon: 'i-mdi-file-document-edit', color: 'gray' },
    { value: 'new', label: 'Nueva', description: 'Orden creada pero pendiente de agendar y preparar.', icon: 'i-mdi-plus-circle', color: 'gray' },
    { value: 'design-revision', label: 'Revisión de Diseño', description: 'Orden en espera de revisión y aprobación de diseño.', icon: 'i-mdi-file-cog', color: 'blue' },
    { value: 'design-approved', label: 'Diseño Aprobado', description: 'Orden con diseño aprobado y lista para producción.', icon: 'i-mdi-file-check', color: 'green' },
    { value: 'in-progress', label: 'En Ejecución', description: 'Orden en proceso activo de ejecución.', icon: 'i-mdi-progress-clock', color: 'orange' },
    { value: 'table', label: 'En Mesa', description: 'Orden en Mesa.', icon: 'i-mdi-table', color: 'blue' },
    { value: 'approval', label: 'Aprobación', description: 'Orden en espera de aprobación final.', icon: 'i-mdi-check-decagram', color: 'blue' },
    { value: 'rework', label: 'Retrabajo', description: 'Orden con problemas que requieren corrección.', icon: 'i-mdi-replay', color: 'red' },
    { value: 'completed', label: 'Finalizada', description: 'Orden completada con éxito y aprobada para su cierre.', icon: 'i-mdi-check-circle', color: 'green' },
    { value: 'rejected', label: 'Rechazada', description: 'Orden rechazada. No se realizará ninguna acción adicional.', icon: 'i-mdi-close-circle', color: 'red' },
  ],
  priority: optionsShared.priority,
} satisfies Options

export const optionsProductionordersMap = {
  Tipo: optionsProductionorders.type,
  Estado: optionsProductionorders.status,
  Prioridad: optionsProductionorders.priority,
}

export const viewSettingsProductionorders: ViewSettings = {
  tableName: 'productionorders',
  typeOptions: optionsShared.views.filter(view => ['grid', 'kanban', 'calendar'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'account.name',
      label: 'Cuenta',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    {
      key: 'productionorderitems.status',
      label: 'Items',
    },
    {
      key: 'filePdf',
      label: 'PDF',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateStart',
      label: 'Fecha de Inicio',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'dateEnd',
      label: 'Fecha de Cierre',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'user.name',
      label: 'Responsable',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'salesorder.name',
      label: 'Orden de venta',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    ...COLUMNS_METADATA,
  ],
  groupBy: 'status',
  groupByOptions: [
    { label: 'Tipo', value: 'type', options: optionsProductionorders.type },
    { label: 'Estado', value: 'status', options: optionsProductionorders.status },
    { label: 'Prioridad', value: 'priority', options: optionsProductionorders.priority },
  ],
}
