<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<Account>
  onSubmit: (event: FormSubmitEvent<Partial<Account>>) => void
  openNestedCallback: (data: Partial<Account>) => void
  title?: string
  disabledFields?: (keyof Account | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { moduleId, userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))
const simple = ref(true)

const state = ref<Partial<Account>>(props.data)

const accounts$ = useAccounts()

const $accountgroups = await useFetch<Accountgroup[]>('/api/db/accountgroups', {
  query: {},
  default: () => [],
})
const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: { moduleId: [moduleId.value], $columns: ['id', 'name', 'image'] },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { type: ['internal'], $columns: ['id', 'name', 'image', 'email'] },
  default: () => [],
})
const $industries = await useFetch<Industry[]>('/api/db/industries', {
  query: { $columns: ['id', 'name', 'description', 'image'] },
  default: () => [],
})

const utils = useAccountsUtils({ $accountgroups, $contacts, $users, $industries })

const items = computed<AccordionItem[]>(() => {
  if (['create'].includes(props.action) && simple.value) {
    return [{ slot: 'simple', label: 'Creación rápida', icon: 'i-mdi-lightning-bolt', defaultOpen: true }]
  }
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.accounts,
      defaultOpen: true,
    },
    {
      slot: 'billing',
      label: 'Información fiscal',
      icon: 'i-mdi-bank',
      defaultOpen: true,
    },
    {
      slot: 'payment',
      label: 'Facturación y pago predeterminado',
      icon: 'i-mdi-credit-card',
      defaultOpen: true,
    },
    {
      slot: 'metrics',
      label: 'Métricas',
      icon: 'i-mdi-chart-line',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Datos adicionales',
      icon: 'i-mdi-code-json',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateBillingType = (type: 'individual' | 'legalEntity' | 'foreignLegalEntity' | 'genericRFC') => {
  switch (type) {
    case 'individual': return state.value.billing = getDefaultBilling({ type })
    case 'legalEntity': return state.value.billing = getDefaultBilling({ type })
    case 'foreignLegalEntity': return state.value.billing = getDefaultBillingForeignLegalEntity({ type })
    case 'genericRFC': return state.value.billing = getDefaultBillingGenericRFC({ type })
  }
}

// deletes billing data if type is cleared
watch(() => state.value.billing?.type, (value) => {
  if (!value) {
    state.value.billing! = {
      address: {},
    }
  }
})

const loading = ref(false)

const onSubmitAccount = async (event: FormSubmitEvent<Partial<Account>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>

    <UForm
      id="form.accounts"
      :validate-on="['submit']"
      :schema="accounts$.schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitAccount"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #simple>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(accounts$.schema, 'name')"
          >
            <InputText
              v-model.defaultcase="state.name"
              autofocus
              :disabled="disabledFields?.includes('name')"
            />
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(accounts$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Responsable"
            name="userId"
            :required="isRequired(accounts$.schema, 'userId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'users',
                idField: 'userId',
                utils: utils,
                options: utils.options.userId.value ?? [],
                disabled: disabledFields?.includes('userId'),
              }"
            />
          </UFormGroup>

          <SlideoverButtonExpand @click="simple = false" />
        </template>

        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(accounts$.schema, 'name')"
          >
            <InputText
              v-model.defaultcase="state.name"
              autofocus
              :disabled="disabledFields?.includes('name')"
            />
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(accounts$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Responsable"
            name="userId"
            :required="isRequired(accounts$.schema, 'userId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'users',
                idField: 'userId',
                utils: utils,
                options: utils.options.userId.value ?? [],
                disabled: disabledFields?.includes('userId'),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Industria"
            name="industryId"
            :required="isRequired(accounts$.schema, 'industryId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'industries',
                idField: 'industryId',
                utils: utils,
                options: utils.options.industryId.value,
                disabled: disabledFields?.includes('industryId'),
                slideoverOpenCreateProps: {},
                refresh: $industries.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Dominio"
            name="domain"
            :required="isRequired(accounts$.schema, 'domain')"
          >
            <InputText
              v-model.lowercase="state.domain"
              type="url"
              :disabled="disabledFields?.includes('domain')"
              help="Terminación del correo electrónico (e.g. 'netzo.dev')"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Grupo empresarial"
            name="accountgroupId"
            :required="isRequired(accounts$.schema, 'accountgroupId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'accountgroups',
                idField: 'accountgroupId',
                utils: utils,
                options: utils.options.accountgroupId.value,
                disabled: disabledFields?.includes('accountgroupId'),
                slideoverOpenCreateProps: {},
                refresh: $accountgroups.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Imagen"
            name="image"
            :required="isRequired(accounts$.schema, 'image')"
          >
            <InputImage
              v-model="state.image"
              table-name="accounts"
              :record-id="state.id"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              table-name="accounts"
              :record-id="state.id"
              accept="*"
            />
          </UFormGroup>
        </template>

        <template #billing>
          <UFormGroup
            label="Tipo de Entidad"
            name="billing.type"
            :required="isRequired(accounts$.schema, 'billing.type')"
          >
            <SelectMenuBaseInfo
              v-model="state.billing!.type"
              :options="utils.options.billing.type"
              :disabled="disabledFields?.includes('billing.type')"
              clearable
              @update:model-value="onUpdateBillingType"
            />
          </UFormGroup>

          <UFormGroup
            v-if="['legalEntity', 'individual'].includes(state.billing?.type)"
            label="Constancia de Situación Fiscal"
            name="fileCsfPdf"
            :required="isRequired(accounts$.schema, 'fileCsfPdf')"
          >
            <InputFile
              v-model="state.fileCsfPdf"
              table-name="accounts"
              :record-id="state.id"
              filename="csf.pdf"
              accept="application/pdf"
              :disabled="disabledFields?.includes('fileCsfPdf')"
            />
          </UFormGroup>

          <UFormGroup
            label="Razón social"
            name="billing.legalName"
            :required="isRequired(accounts$.schema, 'billing.legalName')"
          >
            <InputText
              v-model.defaultcase="state.billing!.legalName"
              :disabled="disabledFields?.includes('billing.legalName') || ['genericRFC'].includes(state.billing?.type)"
            />
          </UFormGroup>

          <UFormGroup
            label="RFC (Tax ID)"
            name="billing.taxId"
            :required="isRequired(accounts$.schema, 'billing.taxId')"
          >
            <InputText
              v-model.defaultcase="state.billing!.taxId"
              :disabled="disabledFields?.includes('billing.taxId') || ['genericRFC', 'foreignLegalEntity'].includes(state.billing?.type)"
            />
          </UFormGroup>

          <UFormGroup
            label="Código de País"
            name="billing.taxCountryCode"
            :required="isRequired(accounts$.schema, 'billing.taxCountryCode')"
          >
            <SelectMenuBase
              v-model="state.billing!.taxCountryCode"
              :options="optionsShared.countryCode"
              :disabled="disabledFields?.includes('billing.taxCountryCode') || ['genericRFC'].includes(state.billing?.type)"
            />
          </UFormGroup>

          <UFormGroup
            label="Régimen fiscal"
            name="billing.satFiscalRegime"
            :required="isRequired(accounts$.schema, 'billing.satFiscalRegime')"
          >
            <SelectMenuBase
              v-model="state.billing!.satFiscalRegime"
              :options="optionsSAT.fiscalRegime"
              :disabled="disabledFields?.includes('billing.satFiscalRegime') || ['genericRFC', 'foreignLegalEntity'].includes(state.billing?.type)"
            />
          </UFormGroup>

          <FieldsetAddress
            v-model="state.billing!.address"
            v-model:name="state.name"
            label="Domicilio fiscal"
            :disabled="['genericRFC'].includes(state.billing?.type)"
            v-bind="{ schema: accounts$.schema, path: 'billing.address', disabledFields }"
            class="col-span-2"
          />
        </template>

        <template #payment>
          <UFormGroup
            label="Uso del CFDI"
            name="payment.satCfdiUse"
            :required="isRequired(accounts$.schema, 'payment.satCfdiUse')"
          >
            <SelectMenuBase
              v-model="state.payment.satCfdiUse"
              :options="optionsSAT.taxCfdiUse"
              :disabled="disabledFields?.includes('payment.satCfdiUse')"
            />
          </UFormGroup>

          <UFormGroup
            label="Método de pago"
            name="payment.satPaymentMethod"
            :required="isRequired(accounts$.schema, 'payment.satPaymentMethod')"
          >
            <SelectMenuBase
              v-model="state.payment.satPaymentMethod"
              :options="optionsSAT.paymentMethod"
              :disabled="disabledFields?.includes('payment.satPaymentMethod')"
            />
          </UFormGroup>

          <UFormGroup
            label="Condiciones de pago"
            name="payment.conditions"
            :required="isRequired(accounts$.schema, 'payment.conditions')"
          >
            <SelectMenuBaseInfo
              v-model="state.payment.conditions"
              :options="utils.options.billing.paymentConditions"
              :disabled="disabledFields?.includes('payment.conditions')"
            />
          </UFormGroup>

          <UFormGroup
            label="Forma de pago"
            name="payment.satPaymentForm"
            :required="isRequired(accounts$.schema, 'payment.satPaymentForm')"
          >
            <SelectMenuBase
              v-model="state.payment.satPaymentForm"
              :options="optionsSAT.paymentForm"
              :disabled="disabledFields?.includes('payment.satPaymentForm')"
            />
          </UFormGroup>

          <UFormGroup
            label="Moneda"
            name="payment.currency"
            :required="isRequired(accounts$.schema, 'payment.currency')"
          >
            <SelectMenuBase
              v-model="state.payment.currency"
              :options="optionsSAT.currency"
              :disabled="disabledFields?.includes('payment.currency')"
            />
          </UFormGroup>
        </template>

        <template #metrics>
          <UFormGroup
            label="Frecuencia de compra"
            name="metrics.purchaseFrequency"
            :required="isRequired(accounts$.schema, 'metrics.purchaseFrequency')"
          >
            <SelectMenuBaseInfo
              v-model="state.metrics.purchaseFrequency"
              :options="utils.options.metrics.purchaseFrequency"
              :disabled="disabledFields?.includes('metrics.purchaseFrequency')"
            />
          </UFormGroup>

          <UFormGroup
            label="Valor de compra promedio"
            name="metrics.purchaseValue"
            :required="isRequired(accounts$.schema, 'metrics.purchaseValue')"
          >
            <InputCurrency
              v-model="state.metrics.purchaseValue"
              icon
              :disabled="disabledFields?.includes('metrics.purchaseValue')"
            />
          </UFormGroup>

          <UFormGroup
            label="Fecha de última compra"
            name="metrics.lastPurchasedAt"
            :required="isRequired(accounts$.schema, 'metrics.lastPurchasedAt')"
          >
            <InputDatetime
              v-model:date="state.metrics.lastPurchasedAt"
              :disabled="disabledFields?.includes('date')"
              hide-checkbox
            />
          </UFormGroup>
        </template>

        <template #data>
          <FieldsetData v-model="state.data" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.accounts"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
