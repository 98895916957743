import { AppCampaignsSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppCampaignsSlideoverProps = ComponentProps<typeof AppCampaignsSlideover>

export type CampaignsUtils = ReturnType<typeof useCampaignsUtils>

export const useCampaigns = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Campaign>('campaigns')

  const slideoverOpenCreate = (props: AppCampaignsSlideoverProps) => {
    return new Promise<Campaign>((resolve, reject) => {
      const { user } = useUserSession()
      const data = getDefaultCampaign({userId: user.value.id, ...props?.data})
      slideover.open(AppCampaignsSlideover, {
        title: 'Crear campaña',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppCampaignsSlideoverProps) => {
    return new Promise<Campaign>((resolve, reject) => {
      const data = getDefaultCampaign(props.data)
      slideover.open(AppCampaignsSlideover, {
        title: 'Editar campaña',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    name: z.string(),
    dateStart: z.string(),
    dateEnd: z.string(),
    userId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Campaign, refresh: () => Promise<void>, utils: CampaignsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'campaigns', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'campaigns', row, refresh, utils),
      getDropdownItem('copyUid', 'campaigns', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'campaigns', row, refresh, utils),
      getDropdownItem('files', 'campaigns', row, refresh, utils),
    ],
    [
      {
        label: 'Crear negocio',
        icon: ICONS.deals,
        click: () => {
          const deals$ = useDeals()
          deals$.slideoverOpenCreate({ data: {
            campaignId: row.id,
          },
          disabledFields: ['campaignId'],
          }).then(refresh)
        },
      },
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'campaigns', row, refresh, utils),
      getDropdownItem('immutable', 'campaigns', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'campaigns', row, refresh, utils),
      getDropdownItem('delete', 'campaigns', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useCampaignsUtils = ({ $users }: {
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsCampaigns,
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsCampaigns.type, 'value'),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
  }

  return { options, maps }
}
