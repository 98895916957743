<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<Activity>
  onSubmit: (event: FormSubmitEvent<Partial<Activity>>) => void
  openNestedCallback: (data: Partial<Activity>) => void
  title?: string
  disabledFields?: (keyof Activity | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { moduleId, userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))
const simple = ref(true)

const state = ref<Partial<Activity>>(props.data)

const activities$ = useTable('activities')

const $deals = await useFetch<Deal[]>('/api/db/deals', {
  query: {},
  default: () => [],
})
const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: {},
  default: () => [],
})
const $branches = await useFetch<Branch[]>('/api/db/branches', {
  query: {},
  default: () => [],
})
const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: { moduleId: [moduleId.value] },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { type: ['internal'], $columns: ['id', 'name', 'image', 'email'] },
  default: () => [],
})

const utils = useActivitiesUtils({ $contacts, $deals, $accounts, $branches, $users })

const items = computed<AccordionItem[]>(() => {
  if (['create'].includes(props.action) && simple.value) {
    return [{ slot: 'simple', label: 'Creación rápida', icon: 'i-mdi-lightning-bolt', defaultOpen: true }]
  }
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.activities,
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Datos adicionales',
      icon: 'i-mdi-code-json',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateDealId = async (dealId: string) => {
  const deal = utils.maps.dealId.value.get(dealId) as Partial<Deal>
  state.value.branchId = deal.branchId
}

if (['create'].includes(props.action) && props.data.dealId) {
  onUpdateDealId(props.data.dealId)
}

const loading = ref(false)

const onSubmitActivity = async (event: FormSubmitEvent<Partial<Activity>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true
  await props.onSubmit(event)
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.activities"
      :validate-on="['submit']"
      :schema="activities$.schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitActivity"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #simple>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(activities$.schema, 'name')"
            :hint="state.uid"
          >
            <UButtonGroup class="flex">
              <InputText
                v-model.defaultcase="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(activities$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Subtipo"
            name="subtype"
            :required="isRequired(activities$.schema, 'subtype')"
          >
            <SelectMenuBaseInfo
              v-model="state.subtype"
              :options="utils.options.subtype"
              :disabled="disabledFields?.includes('subtype')"
            />
          </UFormGroup>

          <UFormGroup
            label="Cuenta"
            name="accountId"
            required
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'accounts',
                idField: 'accountId',
                utils: utils,
                options: utils.options.accountId.value,
                disabled: disabledFields?.includes('accountId'),
                slideoverOpenCreateProps: {},
                refresh: $accounts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Negocio"
            name="dealId"
            :required="isRequired(activities$.schema, 'dealId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'deals',
                idField: 'dealId',
                utils: utils,
                options: utils.maps.dealsByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('dealId'),
                onClickUpdate: onUpdateDealId,
                slideoverOpenCreateProps: {
                  data: { accountId: state.accountId },
                  disabledFields: [],
                },
                refresh: $deals.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(activities$.schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Contactos"
            name="contactIds"
            :required="isRequired(activities$.schema, 'contactIds')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'contacts',
                idField: 'contactIds',
                multiple: true,
                utils: utils,
                options: utils.maps.contactsByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('contactIds') || !state.accountId,
                slideoverOpenCreateProps: {
                  data: { moduleId: 'sales', accountId: state.accountId, branchId: state.branchId, userId: state.userId },
                  disabledFields: ['accountId'],
                },
                refresh: $contacts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Usuarios"
            name="userIds"
            :required="isRequired(activities$.schema, 'userIds')"
          >
            <SelectMenuBase
              v-model="state.userIds"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userIds')"
              multiple
              class="flex-1"
            />
          </UFormGroup>

          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.dateStart"
            v-model:date-end="state.dateEnd"
            :is-required-start-date="isRequired(activities$.schema, 'dateStart')"
            :is-required-end-date="isRequired(activities$.schema, 'dateEnd')"
          />

          <UFormGroup
            label="Comentarios"
            name="text"
            :required="isRequired(activities$.schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>

          <SlideoverButtonExpand @click="simple = false" />
        </template>

        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(activities$.schema, 'name')"
            :hint="state.uid"
          >
            <UButtonGroup class="flex">
              <InputText
                v-model.defaultcase="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(activities$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Subtipo"
            name="subtype"
            :required="isRequired(activities$.schema, 'subtype')"
          >
            <SelectMenuBaseInfo
              v-model="state.subtype"
              :options="utils.options.subtype"
              :disabled="disabledFields?.includes('subtype')"
            />
          </UFormGroup>

          <UFormGroup
            label="Cuenta"
            name="accountId"
            required
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'accounts',
                idField: 'accountId',
                utils: utils,
                options: utils.options.accountId.value,
                disabled: disabledFields?.includes('accountId'),
                slideoverOpenCreateProps: {},
                refresh: $accounts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Negocio"
            name="dealId"
            :required="isRequired(activities$.schema, 'dealId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'deals',
                idField: 'dealId',
                utils: utils,
                options: utils.maps.dealsByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('dealId'),
                onClickUpdate: onUpdateDealId,
                slideoverOpenCreateProps: {
                  data: { accountId: state.accountId },
                  disabledFields: [],
                },
                refresh: $deals.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(activities$.schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Prioridad"
            name="priority"
            :required="isRequired(activities$.schema, 'priority')"
          >
            <SelectMenuBaseInfo
              v-model="state.priority"
              :options="utils.options.priority"
              :disabled="disabledFields?.includes('priority')"
            />
          </UFormGroup>

          <UFormGroup
            label="Sucursal"
            name="branchId"
            :required="isRequired(activities$.schema, 'branchId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'branches',
                idField: 'branchId',
                utils: utils,
                options: utils.maps.branchesByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('branchId') || !state.accountId,
                slideoverOpenCreateProps: {
                  data: { accountId: state.accountId },
                  disabledFields: ['accountId'],
                },
                refresh: $branches.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Contactos"
            name="contactIds"
            :required="isRequired(activities$.schema, 'contactIds')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'contacts',
                idField: 'contactIds',
                multiple: true,
                utils: utils,
                options: utils.maps.contactsByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('contactIds') || !state.accountId,
                slideoverOpenCreateProps: {
                  data: { moduleId: 'sales', accountId: state.accountId, branchId: state.branchId, userId: state.userId },
                  disabledFields: ['accountId'],
                },
                refresh: $contacts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Usuarios"
            name="userIds"
            :required="isRequired(activities$.schema, 'userIds')"
          >
            <SelectMenuBase
              v-model="state.userIds"
              :options="utils.options.userId.value"
              :disabled="disabledFields?.includes('userIds')"
              multiple
              class="flex-1"
            />
          </UFormGroup>

          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.dateStart"
            v-model:date-end="state.dateEnd"
            :is-required-start-date="isRequired(activities$.schema, 'dateStart')"
            :is-required-end-date="isRequired(activities$.schema, 'dateEnd')"
          />

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              table-name="activities"
              :record-id="state.id"
              accept="*"
            />
          </UFormGroup>

          <UFormGroup
            label="Comentarios"
            name="text"
            :required="isRequired(activities$.schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>
        </template>

        <template #data>
          <FieldsetData v-model="state.data" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.activities"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
