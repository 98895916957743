<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<Service>
  onSubmit: (event: FormSubmitEvent<Partial<Service>>) => void
  openNestedCallback: (data: Partial<Service>) => void
  title?: string
  disabledFields?: (keyof Service | string)[]
  readonly?: boolean
  navigateToSubpage?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))
const simple = ref(true)

const state = ref<Partial<Service>>(props.data)

const services$ = useServices()

const $suppliers = await useFetch<Supplier[]>('/api/db/suppliers', {
  query: { },
  default: () => [],
})
const $categories = await useFetch<Category[]>('/api/db/categories', {
  query: {},
  default: () => [],
})

const utils = useServicesUtils({ $suppliers, $categories })

const items = computed<AccordionItem[]>(() => {
  if (['create'].includes(props.action) && simple.value) {
    return [{ slot: 'simple', label: 'Creación rápida', icon: 'i-mdi-lightning-bolt', defaultOpen: true }]
  }
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.services,
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Datos adicionales',
      icon: 'i-mdi-code-json',
      defaultOpen: false,
    },
    {
      slot: 'fiscalData',
      label: 'Información fiscal',
      icon: 'i-mdi-bank',
      defaultOpen: true,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateCategoryId = async (categoryId: string) => {
  const category = utils.maps.categoryId.value.get(categoryId)
  state.value.image = category?.image
  state.value.description = category?.description
}

if (['create'].includes(props.action) && props.data.categoryId) {
  onUpdateCategoryId(props.data.categoryId)
}

const loading = ref(false)

const onSubmitService = async (event: FormSubmitEvent<Partial<Service>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true
  await props.onSubmit(event)
  if (props.navigateToSubpage) {
    router.push(`/catalogs/services/${event.data.id}`)
  }
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.services"
      :validate-on="['submit']"
      :schema="services$.schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitService"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #simple>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(services$.schema, 'name')"
          >
            <InputText
              v-model.defaultcase="state.name"
              autofocus
              :disabled="disabledFields?.includes('name')"
              class="flex-1"
            />
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(services$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Categoría"
            name="caegoryId"
            :required="isRequired(services$.schema, 'categoryId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'categories',
                idField: 'categoryId',
                utils: utils,
                options: utils.options.categoryId.value,
                disabled: disabledFields?.includes('categoryId'),
                onClickUpdate: onUpdateCategoryId,
                slideoverOpenCreateProps: {
                  data: { type: 'services' },
                  disabledFields: ['type'],
                },
                refresh: $categories.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <SlideoverButtonExpand @click="simple = false" />
        </template>

        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(services$.schema, 'name')"
          >
            <InputText
              v-model.defaultcase="state.name"
              autofocus
              :disabled="disabledFields?.includes('name')"
              class="flex-1"
            />
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(services$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Categoría"
            name="caegoryId"
            :required="isRequired(services$.schema, 'categoryId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'categories',
                idField: 'categoryId',
                utils: utils,
                options: utils.options.categoryId.value,
                disabled: disabledFields?.includes('categoryId'),
                onClickUpdate: onUpdateCategoryId,
                slideoverOpenCreateProps: {
                  data: { type: 'services' },
                  disabledFields: ['type'],
                },
                refresh: $categories.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Proveedor"
            name="supplierId"
            :required="isRequired(services$.schema, 'supplierId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'suppliers',
                idField: 'supplierId',
                utils: utils,
                options: utils.options.supplierId.value,
                disabled: disabledFields?.includes('supplierId'),
                slideoverOpenCreateProps: {
                  data: { type: 'supplier' },
                  disabledFields: ['type'],
                },
                refresh: $suppliers.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Imagen"
            name="image"
            :required="isRequired(services$.schema, 'image')"
          >
            <InputImage
              v-model="state.image"
              table-name="services"
              :record-id="state.id"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              table-name="services"
              :record-id="state.id"
              accept="*"
            />
          </UFormGroup>

          <UFormGroup
            label="Descripción"
            name="description"
            :required="isRequired(services$.schema, 'description')"
          >
            <UTextarea
              v-model="state.description"
              resize
              :disabled="disabledFields?.includes('description')"
            />
          </UFormGroup>
        </template>

        <template #fiscalData>
          <UFormGroup
            label="Clave Producto (SAT)"
            name="fiscalData.satProductServiceKey"
            :required="isRequired(services$.schema, 'fiscalData.satProductServiceKey')"
            help="Consulta el catálogo de productos y servicios del SAT"
          >
            <InputBaseLink
              v-model="state.fiscalData!.satProductServiceKey"
              :disabled="disabledFields?.includes('fiscalData.satProductServiceKey')"
              href="https://www.sat.gob.mx/consultas/53693/catalogo-de-productos-y-servicios"
            />
          </UFormGroup>
        </template>

        <template #data>
          <FieldsetData v-model="state.data" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.services"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
