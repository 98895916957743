export const getDealInformation = (data: Partial<DealWithRelations>) => {
  if (!data) return []
  const maps = {
    type: toMapByKey(optionsDeals.type, 'value'),
    subtype: toMapByKey(optionsDeals.subtype, 'value'),
    status: toMapByKey(optionsDeals.status, 'value'),
  }
  return [
    ['Referencia', data.uid],
    ['Nombre', data.name],
    ['Tipo', maps.type.get(data.type!)?.label],
    ['Subtipo', maps.subtype.get(data.subtype!)?.label],
    ['Estado', maps.status.get(data.status!)?.label],
    data.account && ['Cliente', data.account.name],
  ].filter(Boolean).map(([key, value]) => [key, value || '-'])
}

export const optionsDeals = {
  type: [
    { value: 'inbound', label: 'Entrante', description: 'Negocios iniciados por el cliente, generalmente al contactarnos para solicitar información o cotización. Necesitan ser asociados a una solicitud.', icon: 'i-mdi-arrow-down-circle', color: 'green' },
    { value: 'outbound', label: 'Saliente', description: 'Negocios iniciados por nosotros, generalmente a través de actividades de prospección o ventas activas.', icon: 'i-mdi-arrow-up-circle', color: 'orange' },
  ],
  subtype: [
    { value: 'new', label: 'Cliente nuevo', description: 'Negocios con clientes nuevos que no han comprado antes.', icon: 'i-mdi-account-plus', color: 'green' },
    { value: 'existing', label: 'Cliente existente', description: 'Negocios con clientes que ya han comprado antes.', icon: 'i-mdi-account-check', color: 'blue' },
  ],
  status: [
    { value: 'contact', label: 'Contacto', description: 'Se requiere establecer el primer contacto con el prospecto para iniciar la negociación.', icon: 'i-mdi-circle-slice-1', color: 'purple' },
    { value: 'quote-sent', label: 'Cotización Enviada', description: 'La cotización ha sido enviada al cliente y estamos esperando su respuesta.', icon: 'i-mdi-paperclip', color: 'amber' },
    { value: 'quote-accepted', label: 'Cotización Aceptada', description: 'El cliente ha aceptado la cotización y está listo para proceder.', icon: 'i-mdi-checkbox-marked-circle', color: 'blue' },
    { value: 'pending-invoice', label: 'Por Facturar', description: 'La cotización ha sido aceptada y estamos esperando para facturar.', icon: 'i-mdi-receipt', color: 'orange' },
    { value: 'deposit-received', label: 'Anticipo Recibido', description: 'Se ha recibido el anticipo por parte del cliente, y el proceso sigue su curso.', icon: 'i-mdi-cash', color: 'green' },
    { value: 'project-billed', label: 'Proyecto Facturado', description: 'El proyecto ha sido facturado y está en espera de su pago.', icon: 'i-mdi-receipt', color: 'orange' },
    { value: 'project-paid', label: 'Proyecto Cobrado', description: 'El pago total del proyecto ha sido recibido y el negocio ha sido cerrado exitosamente.', icon: 'i-mdi-credit-card-check', color: 'teal' },
    { value: 'lost', label: 'Perdido', description: 'El negocio se perdió por diversos motivos, y no avanzará más en el proceso.', icon: 'i-mdi-close-circle', color: 'red' },
  ],
  priority: optionsShared.priority,
  metrics: {
    frequency: [
      { value: 'one-time', label: 'Una vez', description: 'Valor único que no se repetirá en el tiempo.', color: 'blue', icon: 'i-mdi-repeat-off' },
      { value: 'monthly', label: 'Mensual', description: 'Valor que se repite cada mes.', color: 'green', icon: 'i-mdi-repeat' },
      { value: 'yearly', label: 'Anual', description: 'Valor que se repite cada año.', color: 'orange', icon: 'i-mdi-calendar' },
    ],
    lossReason: [
      { value: 'price', label: 'Precio', description: 'El cliente percibió el precio como demasiado alto.', color: 'red', icon: 'i-mdi-currency-usd' },
      { value: 'competition', label: 'Competencia', description: 'El cliente eligió a un competidor.', color: 'orange', icon: 'i-mdi-account-group' },
      { value: 'features', label: 'Características', description: 'Faltaban características que el cliente necesitaba.', color: 'yellow', icon: 'i-mdi-feature-search' },
      { value: 'timing', label: 'Tiempo', description: 'El momento no era el adecuado para el cliente.', color: 'blue', icon: 'i-mdi-clock-time-four' },
      { value: 'budget', label: 'Presupuesto', description: 'El cliente no tenía presupuesto disponible.', color: 'purple', icon: 'i-mdi-cash' },
      { value: 'support', label: 'Atención', description: 'El cliente no estaba satisfecho con la atención o soporte.', color: 'green', icon: 'i-mdi-account-heart' },
      { value: 'unclear-value', label: 'Valor poco claro', description: 'El cliente no entendió claramente el valor del producto.', color: 'gray', icon: 'i-mdi-help-circle' },
      { value: 'other', label: 'Otro', description: 'Razón no especificada o diferente.', color: 'black', icon: 'i-mdi-asterisk' },
    ],
  },
} satisfies Options

export const optionsDealsMap = {
  Tipo: optionsDeals.type,
  Subtipo: optionsDeals.subtype,
  Estado: optionsDeals.status,
  Prioridad: optionsDeals.priority,
  Métricas: {
    'Frecuencia de Negocio': optionsDeals.metrics.frequency,
    'Razón de pérdida': optionsDeals.metrics.lossReason,
  },
}

export const viewSettingsDeals: ViewSettings = {
  tableName: 'deals',
  typeOptions: optionsShared.views.filter(view => ['grid', 'kanban'].includes(view.value)),
  type: 'grid',
  pagination: { page: 1, pageSize: 25 },
  paginationOptions: optionsShared.page,
  compact: false,
  columns: [
    {
      key: 'select',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'actions',
      disabled: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'name',
      label: 'Nombre',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'uid',
      label: 'Referencia',
      sortable: true,
      rowClass: 'text-xs', // for <td>
    },
    {
      key: 'type',
      label: 'Tipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'subtype',
      label: 'Subtipo',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'status',
      label: 'Estado',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'priority',
      label: 'Prioridad',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'account.name',
      label: 'Cliente',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'contact.name',
      label: 'Contact',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'user.name',
      label: 'Responsable',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'text',
      label: 'Detalles',
      class: 'min-w-[300px]',
    },
    {
      key: 'metrics.frequency',
      label: 'Frecuencia',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'metrics.probability',
      label: 'Probabilidad',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'metrics.value',
      label: 'Valor del Negocio',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'metrics.valueReal',
      label: 'Valor Real',
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'metrics.lossReason',
      label: 'Razón de Pérdida',
      sortable: true,
      class: 'text-center', // for <th>
      rowClass: 'text-center', // for <td>
    },
    {
      key: 'branch.name',
      label: 'Sucursal',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'campaign.name',
      label: 'Campaña',
      sortable: true,
      class: 'max-w-[300px]',
    },
    {
      key: 'tags',
      label: 'Etiquetas',
    },
    {
      key: 'files',
      label: 'Archivos',
      class: 'max-w-[300px] overflow-x-auto',
    },
    ...COLUMNS_METADATA,
  ],
  groupBy: 'status',
  groupByOptions: [
    { label: 'Tipo', value: 'type', options: optionsDeals.type },
    { label: 'Subtipo', value: 'subtype', options: optionsDeals.subtype },
    { label: 'Estado', value: 'status', options: optionsDeals.status },
    { label: 'Prioridad', value: 'priority', options: optionsDeals.priority },
  ],
}
