<script setup lang="ts">
const props = defineProps<{
  tableName: TableName
  recordId: string
  title?: string
  data: Partial<Note>
}>()

const query = ref<ViewQuery>({
  // tableName: [props.tableName],
  // recordId: [props.recordId],
  // userId: [],
  archivedAt: '$isNull',
  $search: { columns: ['text'], value: '' },
  $sort: { column: 'createdAt', direction: 'desc' },
  // $pagination: view.value.pagination,
  $with: relationsNotes,
})
const $notes = await useFetch<NoteWithRelations[]>('/api/db/notes', {
  query: computed(() => {
    const { tableName, recordId } = props
    return { ...query.value, tableName, recordId }
  }),
  default: () => [],
})

const notes$ = useNotes()

const utils = useNotesUtils()
</script>

<template>
  <UDashboardSlideover
    :title="title"
    :ui="{ width: 'min-w-[40vw]', body: { base: 'px-0' }, footer: { padding: 'pt-0' } }"
  >
    <div class="flex flex-col h-full gap-3">
      <AppNotesFormNote
        :note="data"
        :refresh="$notes.refresh"
        class="mx-4"
      />

      <ViewPlaceholder
        :loading="['pending'].includes($notes.status.value)"
        :data="$notes.data.value"
        :icon="ICONS.notes"
        label="No hay notas para mostrar."
      >
        <div class="flex-1 flex flex-col gap-3 px-4">
          <AppNotesViewListItem
            v-for="(row, i) in $notes.data.value"
            :key="`note-${row.id}-${i}`"
            :row="row"
            :dropdown-items="notes$.getDropdownItems(row, $notes.refresh, utils)"
          />
        </div>
      </ViewPlaceholder>
    </div>
  </UDashboardSlideover>
</template>
