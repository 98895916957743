<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<Installationorder>
  onSubmit: (event: FormSubmitEvent<Partial<Installationorder>>) => void
  openNestedCallback: (data: Partial<Installationorder>) => void
  title?: string
  disabledFields?: (keyof Installationorder | string)[]
  readonly?: boolean
  navigateToSubpage?: boolean
}>()

const router = useRouter()
const { user } = useUserSession()
const { moduleId, userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))
const simple = ref(true)

const state = ref<Partial<Installationorder>>(props.data)

const installationorders$ = useInstallationorders()

const $accounts = await useFetch<Account[]>('/api/db/accounts', {
  query: {},
  default: () => [],
})
const $branches = await useFetch<Branch[]>('/api/db/branches', {
  query: { },
  default: () => [],
})
const $contacts = await useFetch<Contact[]>('/api/db/contacts', {
  query: { moduleId: 'sales' },
  default: () => [],
})
const $deals = await useFetch<Deal[]>('/api/db/deals', {
  query: { $with: { account: true } },
  default: () => [],
})
const $productionorders = await useFetch<Productionorder[]>('/api/db/productionorders', {
  query: { $with: { account: true } },
  default: () => [],
})
const $salesorders = await useFetch<SalesorderWithRelations[]>('/api/db/salesorders', {
  query: { $with: relationsSalesorders },
  default: () => [],
})
const $users = await useFetch<User[]>('/api/db/users', {
  query: { type: ['internal'], $columns: ['id', 'name', 'image', 'email'] },
  default: () => [],
})

const utils = useInstallationordersUtils({ $accounts, $branches, $contacts, $deals, $productionorders, $salesorders, $users })

const items = computed<AccordionItem[]>(() => {
  if (['create'].includes(props.action) && simple.value) {
    return [{ slot: 'simple', label: 'Creación rápida', icon: 'i-mdi-lightning-bolt', defaultOpen: true }]
  }
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: 'i-mdi-toolbox',
      defaultOpen: true,
    },
    {
      slot: 'installation',
      label: 'Instalación',
      icon: 'i-mdi-truck-delivery',
      defaultOpen: true,
    },
    {
      slot: 'approval',
      label: 'Aprobación',
      icon: 'i-mdi-check-decagram',
      defaultOpen: true,
    },
    {
      slot: 'pdfSettings',
      label: 'Configuración del PDF',
      icon: 'i-mdi-file-pdf',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const onUpdateDeliveryAddress = () => {
  if (state.value.useAccountAddress) {
    const account = utils.maps.accountId.value.get(state.value.accountId)
    if (account) {
      state.value.address = account.billing?.address ?? {} as Address
    }
  }
  else if (state.value.branchId) {
    state.value.address = utils.maps.branchId.value.get(state.value.branchId)?.address ?? {} as Address
  }
}

const onUpdateSalesorderId = async (id: string) => {
  const salesorder = utils.maps.salesorderId.value.get(id)
  if (salesorder) {
    state.value.branchId = salesorder.branchId
    state.value.useAccountAddress = salesorder.delivery.useAccountAddress
    state.value.contactId = salesorder.contactId ?? null
    state.value.address = salesorder.delivery.address
    onUpdateDeliveryAddress()
  }
}

const selectedTab = ref(state.value.useAccountAddress ? 0 : 1)

watch(() => state.value.useAccountAddress, () => {
  selectedTab.value = state.value.useAccountAddress ? 0 : 1
})

const tabs = [
  { label: 'Domicilio fiscal', icon: ICONS.accounts },
  { label: 'Sucursal', icon: ICONS.branches },
]

const onUpdateTab = (tab: number) => {
  if (tab === 0) { state.value.useAccountAddress = true }
  else { state.value.useAccountAddress = false }
  onUpdateDeliveryAddress()
}

const onUpdateBranchId = async (branchId: string) => {
  const branch = utils.maps.branchId.value.get(branchId)
  state.value!.address = branch?.address ?? ({} as Address)
}

const loading = ref(false)

const onSubmitInstallationorder = async (event: FormSubmitEvent<Partial<Installationorder>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true

  if (['pending', 'EXW'].includes(event.data?.incoterm)) {
    delete event.data.branchId
    event.data.address = {}
  }
  if (event.data?.useAccountAddress) delete event.data.branchId

  await props.onSubmit(event)
  if (props.navigateToSubpage) {
    router.push(`/fulfillment/installationorders/${event.data.id}`)
  }
}
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.installationorders"
      :validate-on="['submit']"
      :schema="installationorders$.schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitInstallationorder"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #simple>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(installationorders$.schema, 'name')"
            :hint="state.uid"
          >
            <UButtonGroup class="flex">
              <InputText
                v-model.defaultcase="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(installationorders$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Cuenta"
            name="accountId"
            :required="isRequired(installationorders$.schema, 'accountId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'accounts',
                idField: 'accountId',
                utils: utils,
                options: utils.options.accountId.value,
                disabled: disabledFields?.includes('accountId'),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Negocio"
            name="dealId"
            :required="isRequired(installationorders$.schema, 'dealId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'deals',
                idField: 'dealId',
                utils: utils,
                options: utils.maps.dealsByAccountId.value.get(state.accountId)?.map(toOption) || [],
                disabled: disabledFields?.includes('dealId'),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Orden de venta"
            name="salesorderId"
            :required="isRequired(installationorders$.schema, 'salesorderId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'salesorders',
                idField: 'salesorderId',
                utils: utils,
                options: utils.maps.salesorderByDealId.value.get(state.dealId)?.map(toOption) || [],
                disabled: disabledFields?.includes('salesorderId'),
                onClickUpdate: onUpdateSalesorderId,
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Orden de producción"
            name="productionorderId"
            :required="isRequired(installationorders$.schema, 'productionorderId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'productionorders',
                idField: 'productionorderId',
                utils: utils,
                options: utils.maps.productionordersBySalesorderId.value.get(state.salesorderId)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('salesorderId'),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Contacto (Instalación)"
            name="contactId"
            :required="isRequired(installationorders$.schema, 'contactId')"
            :hint="`(${toPhoneString(utils.maps.contactId.value.get(state.contactId)?.phone)})`"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'contacts',
                idField: 'contactId',
                utils: utils,
                options: utils.maps.contactsByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: !state.accountId,
                slideoverOpenCreateProps: {
                  data: { moduleId: 'sales', accountId: state.accountId, branchId: state.branchId, userId: state.userId },
                  disabledFields: ['accountId'],
                },
                refresh: $contacts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Contacto (Aprobación)"
            name="contactIdApproval"
            :required="isRequired(installationorders$.schema, 'contactIdApproval')"
            :hint="`(${toPhoneString(utils.maps.contactId.value.get(state.contactIdApproval)?.phone)})`"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'contacts',
                idField: 'contactIdApproval',
                utils: utils,
                options: utils.maps.contactsByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: !state.accountId,
                slideoverOpenCreateProps: {
                  data: { moduleId: 'sales', accountId: state.accountId, branchId: state.branchId, userId: state.userId },
                  disabledFields: ['accountId'],
                },
                refresh: $contacts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Horario disponible"
            name="openHours"
            :required="isRequired(installationorders$.schema, 'openHours')"
          >
            <InputText
              v-model.defaultcase="state.openHours"
              :disabled="disabledFields?.includes('openHours')"
            />
          </UFormGroup>

          <UFormGroup
            label="Superficies"
            name="surfaces"
            :required="isRequired(installationorders$.schema, 'surfaces')"
          >
            <SelectMenuBaseInfo
              v-model="state.surfaces"
              :options="utils.options.surfaces"
              :disabled="disabledFields?.includes('surfaces')"
              multiple
            />
          </UFormGroup>

          <UFormGroup
            label="Retiro"
            name="removal"
            :required="isRequired(installationorders$.schema, 'removal')"
          >
            <InputText
              v-model.defaultcase="state.removal"
              :disabled="disabledFields?.includes('removal')"
            />
          </UFormGroup>

          <UFormGroup
            label="Altura máxima (m)"
            name="maxHeight"
            :required="isRequired(installationorders$.schema, 'maxHeight')"
          >
            <UInput
              v-model.number="state.maxHeight"
              type="number"
              inputmode="decimal"
              icon="i-mdi-ruler"
              :disabled="disabledFields?.includes('maxHeight')"
            />
          </UFormGroup>

          <UFormGroup
            label="Indicaciones para el instalador"
            name="items"
            :required="isRequired(installationorders$.schema, 'items')"
          >
            <AppInstallationordersInputItems
              v-model="state.items"
              placeholder="Añadir más indicaciones"
              v-bind="{ schema: installationorders$.schema, recordId: state.id }"
            />
          </UFormGroup>

          <UFormGroup
            label="Dirección de entrega"
            name="branchId"
            :required="state.useAccountAddress"
          >
            <UTabs
              v-model="selectedTab"
              :items="tabs"
              @update:model-value="onUpdateTab"
            />
            <SelectMenuBaseReference
              v-if="!state.useAccountAddress"
              v-model="state"
              v-bind="{
                tableName: 'branches',
                idField: 'branchId',
                options: utils.maps.branchesByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('branchId') || !state.accountId,
                onClickUpdate: onUpdateBranchId,
                slideoverOpenCreateProps: {
                  data: { accountId: state.accountId, contactId: state.contactId },
                  disabledFields: ['accountId'],
                },
                refresh: $branches.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...state, data }).then(refresh),
              }"
            />

            <FieldsetAddress
              v-model="state.address"
              v-model:name="state.name"
              disabled
              v-bind="{ schema: installationorders$.schema, path: 'address', disabledFields }"
            />
            <SlideoverButtonExpand @click="simple = false" />
          </uformgroup>
        </template>

        <template #general>
          <UFormGroup
            label="Nombre"
            name="name"
            :required="isRequired(installationorders$.schema, 'name')"
            :hint="state.uid"
          >
            <UButtonGroup class="flex">
              <InputText
                v-model.defaultcase="state.name"
                :disabled="disabledFields?.includes('name')"
                autofocus
                class="flex-1"
              />
              <ButtonGenerateString @click="state.name = state.uid" />
            </UButtonGroup>
          </UFormGroup>

          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(installationorders$.schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              :disabled="disabledFields?.includes('type')"
            />
          </UFormGroup>

          <UFormGroup
            label="Cuenta"
            name="accountId"
            :required="isRequired(installationorders$.schema, 'accountId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'accounts',
                idField: 'accountId',
                utils: utils,
                options: utils.options.accountId.value,
                disabled: disabledFields?.includes('accountId'),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Negocio"
            name="dealId"
            :required="isRequired(installationorders$.schema, 'dealId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'deals',
                idField: 'dealId',
                utils: utils,
                options: utils.maps.dealsByAccountId.value.get(state.accountId)?.map(toOption) || [],
                disabled: disabledFields?.includes('dealId'),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Orden de venta"
            name="salesorderId"
            :required="isRequired(installationorders$.schema, 'salesorderId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'salesorders',
                idField: 'salesorderId',
                utils: utils,
                options: utils.maps.salesorderByDealId.value.get(state.dealId)?.map(toOption) || [],
                disabled: disabledFields?.includes('salesorderId'),
                onClickUpdate: onUpdateSalesorderId,
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Orden de producción"
            name="productionorderId"
            :required="isRequired(installationorders$.schema, 'productionorderId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'productionorders',
                idField: 'productionorderId',
                utils: utils,
                options: utils.maps.productionordersBySalesorderId.value.get(state.salesorderId)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('salesorderId'),
              }"
            />
          </UFormGroup>
        </template>

        <template #details>
          <UFormGroup
            label="Estado"
            name="status"
            :required="isRequired(installationorders$.schema, 'status')"
          >
            <SelectMenuBaseInfo
              v-model="state.status"
              :options="utils.options.status"
              :disabled="disabledFields?.includes('status')"
            />
          </UFormGroup>

          <UFormGroup
            label="Prioridad"
            name="priority"
            :required="isRequired(installationorders$.schema, 'priority')"
          >
            <SelectMenuBaseInfo
              v-model="state.priority"
              :options="utils.options.priority"
              :disabled="disabledFields?.includes('priority')"
            />
          </UFormGroup>

          <UFormGroup label="Etiquetas" name="tags">
            <SelectMenuCreatableString
              v-model="state.tags"
              :options="state.tags"
              creatable
            />
          </UFormGroup>

          <UFormGroup label="Archivos" name="files">
            <InputFiles
              v-model="state.files"
              table-name="installationorders"
              :record-id="state.id"
              accept="*"
            />
          </UFormGroup>

          <UFormGroup
            label="Comentarios"
            name="text"
            :required="isRequired(installationorders$.schema, 'text')"
          >
            <UTextarea
              v-model="state.text"
              autoresize
              :disabled="disabledFields?.includes('text')"
            />
          </UFormGroup>
        </template>

        <template #installation>
          <UFormGroup
            label="Instalador"
            name="userId"
            :required="isRequired(installationorders$.schema, 'userId')"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'users',
                idField: 'userId',
                utils: utils,
                options: utils.options.userId.value ?? [],
                disabled: disabledFields?.includes('userId'),
              }"
            />
          </UFormGroup>

          <FieldsetDateRange
            v-model:is-datetime="state.isDatetime"
            v-model:date-start="state.dateStart"
            v-model:date-end="state.dateEnd"
            :is-required-start-date="isRequired(installationorders$.schema, 'dateStart')"
            :is-required-end-date="isRequired(installationorders$.schema, 'dateEnd')"
            :disabled-fields="disabledFields"
          />

          <UFormGroup
            label="Contacto (Instalación)"
            name="contactId"
            :required="isRequired(installationorders$.schema, 'contactId')"
            :hint="`(${toPhoneString(utils.maps.contactId.value.get(state.contactId)?.phone)})`"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'contacts',
                idField: 'contactId',
                utils: utils,
                options: utils.maps.contactsByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: !state.accountId,
                slideoverOpenCreateProps: {
                  data: { moduleId: 'sales', accountId: state.accountId, branchId: state.branchId, userId: state.userId },
                  disabledFields: ['accountId'],
                },
                refresh: $contacts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup
            label="Horario disponible"
            name="openHours"
            :required="isRequired(installationorders$.schema, 'openHours')"
          >
            <InputText
              v-model.defaultcase="state.openHours"
              :disabled="disabledFields?.includes('openHours')"
            />
          </UFormGroup>

          <UFormGroup
            label="Retiro"
            name="removal"
            :required="isRequired(installationorders$.schema, 'removal')"
          >
            <InputText
              v-model.defaultcase="state.removal"
              :disabled="disabledFields?.includes('removal')"
            />
          </UFormGroup>

          <UFormGroup
            label="Altura máxima (m)"
            name="maxHeight"
            :required="isRequired(installationorders$.schema, 'maxHeight')"
          >
            <UInput
              v-model.number="state.maxHeight"
              type="number"
              inputmode="decimal"
              icon="i-mdi-ruler"
              :disabled="disabledFields?.includes('maxHeight')"
            />
          </UFormGroup>

          <UFormGroup
            label="Indicaciones para el instalador"
            name="items"
            :required="isRequired(installationorders$.schema, 'items')"
          >
            <AppInstallationordersInputItems
              :id="state.id"
              v-model="state.items"
              placeholder="Añadir más indicaciones"
              v-bind="{ schema: installationorders$.schema }"
            />
          </UFormGroup>

          <UFormGroup
            label="Dirección de instalación"
            name="branchId"
            :required="state.useAccountAddress"
          >
            <UTabs
              v-model="selectedTab"
              :items="tabs"
              @update:model-value="onUpdateTab"
            />
            <SelectMenuBaseReference
              v-if="!state.useAccountAddress"
              v-model="state"
              v-bind="{
                tableName: 'branches',
                idField: 'branchId',
                options: utils.maps.branchesByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: disabledFields?.includes('branchId') || !state.accountId,
                onClickUpdate: onUpdateBranchId,
                slideoverOpenCreateProps: {
                  data: { accountId: state.accountId, contactId: state.contactId },
                  disabledFields: ['accountId'],
                },
                refresh: $branches.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...state, data }).then(refresh),
              }"
            />

            <FieldsetAddress
              v-model="state.address"
              v-model:name="state.name"
              disabled
              v-bind="{ schema: installationorders$.schema, path: 'address', disabledFields }"
            />
          </UFormGroup>
        </template>

        <template #approval>
          <UFormGroup
            label="Contacto (Aprobación)"
            name="contactIdApproval"
            :required="isRequired(installationorders$.schema, 'contactIdApproval')"
            :hint="`(${toPhoneString(utils.maps.contactId.value.get(state.contactIdApproval)?.phone)})`"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: 'contacts',
                idField: 'contactIdApproval',
                utils: utils,
                options: utils.maps.contactsByAccountId.value.get(state.accountId)?.map(toOption) ?? [],
                disabled: !state.accountId,
                slideoverOpenCreateProps: {
                  data: { moduleId: 'sales', accountId: state.accountId, branchId: state.branchId, userId: state.userId },
                  disabledFields: ['accountId'],
                },
                refresh: $contacts.refresh,
                openNestedCallback: (data) => openNestedCallback({ ...props, data }).then(refresh),
              }"
            />
          </UFormGroup>

          <UFormGroup label="Imágenes" name="images">
            <InputImages
              v-model="state.approval.images"
              table-name="installationorders"
              :record-id="state.id"
              type="imagesapproval"
              :data="{ public: true, immutable: true }"
            />
          </UFormGroup>

          <AppInstallationordersFieldsetApproval
            v-model="state"
            :utils="utils"
            :schema="installationorders$.schema"
            :refresh="$contacts.refresh"
            disabled
          />
        </template>

        <template #data>
          <FieldsetData v-model="state.data" :utils="utils" />
        </template>

        <template #pdfSettings>
          <FieldsetPdfSettings v-model="state!.pdfSettings" :cols="2" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.installationorders"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
