import { AppSuppliersSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppSuppliersSlideoverProps = ComponentProps<typeof AppSuppliersSlideover>

export type SuppliersUtils = ReturnType<typeof useSuppliersUtils>

export const useSuppliers = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Supplier>('suppliers')

  const slideoverOpenCreate = (props: AppSuppliersSlideoverProps) => {
    return new Promise<Supplier>((resolve, reject) => {
      const { user } = useUserSession()
      const data = getDefaultSupplier({userId: user.value.id, ...props?.data})
      slideover.open(AppSuppliersSlideover, {
        title: 'Crear proveedor',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppSuppliersSlideoverProps) => {
    return new Promise<Supplier>((resolve, reject) => {
      const data = getDefaultSupplier(props.data)
      slideover.open(AppSuppliersSlideover, {
        title: 'Editar proveedor',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    name: z.string(),
    userId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Supplier, refresh: () => Promise<void>, utils: SuppliersUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'suppliers', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'suppliers', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'suppliers', row, refresh, utils),
      getDropdownItem('files', 'suppliers', row, refresh, utils),
    ],
    [
      {
        label: 'Agregar contacto',
        icon: ICONS.contacts,
        click: () => {
          const contacts$ = useContacts()
          contacts$.slideoverOpenCreate({
            data: { type: 'purchases', supplierId: row.id, userId: row.userId },
            disabledFields: ['type', 'supplierId'],
          }).then(refresh)
        },
      },
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'suppliers', row, refresh, utils),
      getDropdownItem('immutable', 'suppliers', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'suppliers', row, refresh, utils),
      getDropdownItem('delete', 'suppliers', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useSuppliersUtils = ({ $contacts, $users }: {
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsSuppliers,
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsSuppliers.type, 'value'),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
  }

  return { options, maps }
}
