import { AppAccountsSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppAccountsSlideoverProps = ComponentProps<typeof AppAccountsSlideover>

export type AccountsUtils = ReturnType<typeof useAccountsUtils>

export const useAccounts = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Account>('accounts')

  const slideoverOpenCreate = (props: AppAccountsSlideoverProps) => {
    return new Promise<Account>((resolve, reject) => {
      const { user } = useUserSession()
      const data = getDefaultAccount({ userId: user.value.id, ...props?.data })
      slideover.open(AppAccountsSlideover, {
        title: 'Crear cuenta',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
      })
    })
  }

  const slideoverOpenEdit = (props: AppAccountsSlideoverProps) => {
    return new Promise<Account>((resolve, reject) => {
      const data = getDefaultAccount(props.data)
      slideover.open(AppAccountsSlideover, {
        title: 'Editar cuenta',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schema = z.object({
    type: z.string(),
    name: z.string(),
    userId: z.string(),
  })

  // actions:

  const getDropdownItems = (row: Account, refresh: () => Promise<void>, utils: AccountsUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'accounts', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'accounts', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'accounts', row, refresh, utils),
      getDropdownItem('files', 'accounts', row, refresh, utils),
    ],
    [
      {
        label: 'Agregar contacto',
        icon: ICONS.contacts,
        click: () => {
          const contacts$ = useContacts()
          contacts$.slideoverOpenCreate({
            data: { moduleId: [moduleId.value], accountId: row.id, userId: row.userId },
            disabledFields: ['type', 'accountId'],
          }).then(refresh)
        },
      },
      {
        label: 'Agregar sucursal',
        icon: ICONS.branches,
        click: () => {
          const branches$ = useBranches()
          branches$.slideoverOpenCreate({
            data: { accountId: row.id },
            disabledFields: ['accountId'],
          }).then(refresh)
        },
      },
      {
        label: 'Crear negocio',
        icon: ICONS.deals,
        click: () => {
          const deals$ = useDeals()
          deals$.slideoverOpenCreate({
            data: {
              type: 'outbound',
              accountId: row.id,
              userId: row.userId,
            },
            disabledFields: ['type', 'accountId'],
          }).then(refresh)
        },
      },
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'accounts', row, refresh, utils),
      getDropdownItem('immutable', 'accounts', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'accounts', row, refresh, utils),
      getDropdownItem('delete', 'accounts', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schema,
    getDropdownItems,
  }
}

export const useAccountsUtils = ({ $accountgroups, $contacts, $users, $industries }: {
  $accountgroups?: Awaited<ReturnType<typeof useFetch<Accountgroup[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
  $industries?: Awaited<ReturnType<typeof useFetch<Industry[]>>>
} = {}) => {
  const options = {
    ...optionsAccounts,
    accountgroupId: computed(() => $accountgroups?.data.value.map(toOption)),
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
    industryId: computed(() => $industries?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsAccounts.type, 'value'),
    satCfdiUse: toMapByKey(optionsSAT.taxCfdiUse, 'value'),
    billing: {
      satFiscalRegime: toMapByKey(optionsSAT.fiscalRegime, 'value'),
      type: toMapByKey(optionsAccounts.billing.type, 'value'),
      country: toMapByKey(optionsShared.countryCode, 'value'),
      currency: toMapByKey(optionsSAT.currency, 'value'),
      form: toMapByKey(optionsSAT.paymentForm, 'value'),
    },
    payment: {
      condition: toMapByKey(optionsAccounts.billing.paymentConditions, 'value'),
      method: toMapByKey(optionsSAT.paymentMethod, 'value'),
      currency: toMapByKey(optionsSAT.currency, 'value'),
      forms: toMapByKey(optionsSAT.paymentForm, 'value'),
    },
    metrics: {
      purchaseFrequency: toMapByKey(optionsAccounts.metrics.purchaseFrequency, 'value'),
      purchaseValue: toMapByKey(optionsAccounts.metrics.purchaseValue, 'value'),
    },
    accountgroupId: computed(() => toMapByKey($accountgroups?.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    industryId: computed(() => toMapByKey($industries?.data.value, 'id')),
  }

  return { options, maps }
}
