import { AppQuotesSlideover } from '#components'
import { z } from '#netzo/shared/utils/zod'
import type { DropdownItem } from '#ui/types'
import type { ComponentProps } from 'vue-component-type-helpers'

// types:

type AppQuotesSlideoverProps = ComponentProps<typeof AppQuotesSlideover>

export type QuotesUtils = ReturnType<typeof useQuotesUtils>

export const useQuotes = () => {
  const {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
  } = useRows<Quote>('quotes')

  const slideoverOpenCreate = (props: AppQuotesSlideoverProps) => {
    return new Promise<Quote>((resolve, reject) => {
      const { user } = useUserSession()
      const data = getDefaultQuote({ userId: user.value.id, ...props?.data })
      slideover.open(AppQuotesSlideover, {
        type: 'POST',
        title: 'Crear cotización',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitCreate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'create',
        openNestedCallback: slideoverOpenCreate,
        navigateToSubpage: true,
      })
    })
  }

  const slideoverOpenEdit = (props: AppQuotesSlideoverProps) => {
    return new Promise<Quote>((resolve, reject) => {
      const data = getDefaultQuote(props.data)
      slideover.open(AppQuotesSlideover, {
        title: 'Editar cotización',
        ...props,
        data,
        onSubmit: props?.onSubmit ?? (async (event) => {
          try {
            const result = await onSubmitUpdate(event.data)
            resolve(result)
          }
          catch (error) {
            reject(error)
          }
          finally {
            slideover.close() // ensures slideover is always closed
          }
        }),
        action: 'edit',
        openNestedCallback: slideoverOpenEdit,
      })
    })
  }

  const schemaPendingDelivery = z.object({
    status: z.string(),
    type: z.string(),
    name: z.string(),
    issueDate: z.string(),
    expirationDate: z.string(),
    payment: paymentSchema,
    delivery: z.object({
      incoterm: z.enum(['pending', 'EXW']),
      schedule: z.string(),
      isDatetime: z.boolean().optional(),
      date: z.string().nullable().optional(),
      useAccountAddress: z.boolean(),
      address: z.object({}),
      text: z.string().nullable().optional(),
    }),
    accountId: z.string(),
    dealId: z.string(),
    userId: z.string(),
  })

  const schemaWithDelivery = z.object({
    status: z.string(),
    type: z.string(),
    name: z.string(),
    issueDate: z.string(),
    expirationDate: z.string(),
    payment: paymentSchema,
    delivery: z.object({
      incoterm: z.enum(['FCA', 'CPT', 'CIP', 'DAP', 'DPU', 'DDP', 'FAS', 'FOB', 'CFR', 'CIF']),
      schedule: z.string(),
      isDatetime: z.boolean().optional(),
      date: z.string().nullable().optional(),
      useAccountAddress: z.boolean(),
      address: z.object({
        street: z.string().nullable(),
        numberExterior: z.string().nullable(),
        numberInterior: z.string().nullable().optional(),
        neighborhood: z.string().nullable().optional(),
        city: z.string().nullable(),
        district: z.string().nullable().optional(),
        state: z.string().nullable(),
        country: z.string().nullable(),
        postalCode: z.string().nullable(),
      }),
      text: z.string().nullable().optional(),
    }),
    accountId: z.string(),
    dealId: z.string(),
    userId: z.string(),
  })
  // actions:

  const getDropdownItems = (row: Quote, refresh: () => Promise<void>, utils: QuotesUtils, dropdownItemsAction: DropdownItem[] = []): DropdownItem[][] => [
    [
      getDropdownItem('editOrView', 'quotes', row, refresh, utils),
      getDropdownItem('filePdf', 'quotes', row, refresh, utils),
    ],
    [
      getDropdownItem('copyId', 'quotes', row, refresh, utils),
      getDropdownItem('copyUid', 'quotes', row, refresh, utils),
    ],
    [
      getDropdownItem('notes', 'quotes', row, refresh, utils),
      getDropdownItem('files', 'quotes', row, refresh, utils),
    ],
    [
      {
        label: 'Registrar orden de venta',
        icon: ICONS.salesorders,
        click: () => {
          const salesorders$ = useSalesorders()
          salesorders$.slideoverOpenCreate({
            data: { accountId: row.accountId,
              dealId: row.dealId,
              quoteId: row.id,
            },
          }).then(refresh)
        },
      } as DropdownItem,
      ...dropdownItemsAction,
    ],
    [
      getDropdownItem('info', 'quotes', row, refresh, utils),
      getDropdownItem('immutable', 'quotes', row, refresh, utils),
    ],
    [
      getDropdownItem('archive', 'quotes', row, refresh, utils),
      getDropdownItem('delete', 'quotes', row, refresh, utils),
    ],
  ].filter(items => items?.length)

  // files:

  const createPdf = async (data: QuoteWithRelations, utils: QuotesUtils) => {
    const documentDefinition = await createPdfmakeDocumentDefinitionQuotes(data, utils)
    const pdf = await usePDFMake().createPdf(documentDefinition)
    return pdf
  }

  // updates the quote.files array (to be used after POST/PATCH)
  async function createFilePdf(data: Partial<Quote>, utils: QuotesUtils) {
    const { uploadFile } = useApiBlob('quotes', data.id)
    const [existingData] = await $fetch<QuoteWithRelations[]>(`/api/db/quotes`, {
      query: { id: data.id, $with: relationsQuotes },
    })
    const newData = { ...existingData, ...data } as QuoteWithRelations
    const pdf = await createPdf(newData, utils)
    // IMPORTANT: wrap with a Promise to await the blob
    const blob: Blob = await new Promise(resolve => pdf.getBlob(blob => resolve(blob)))
    const file = new File([blob], 'quote.pdf', { type: 'application/pdf' })
    return uploadFile('filePdf', file)
  }

  return {
    onSubmitCreate,
    onSubmitUpdate,
    onSubmitDelete,
    onSubmitUpdateMultiple,
    slideover,
    modal,
    slideoverOpenInformation,
    slideoverOpenFilePreview,
    slideoverOpenFilePreviewPdf,
    modalOpenDelete,
    modalOpenShareFile,
    slideoverOpenCreate,
    slideoverOpenEdit,
    schemaPendingDelivery,
    schemaWithDelivery,
    getDropdownItems,
    createPdf,
    createFilePdf,
  }
}

export const useQuotesUtils = ({ $accounts, $branches, $contacts, $deals, $financialaccounts, $users }: {
  $accounts?: Awaited<ReturnType<typeof useFetch<Account[]>>>
  $branches?: Awaited<ReturnType<typeof useFetch<Branch[]>>>
  $contacts?: Awaited<ReturnType<typeof useFetch<Contact[]>>>
  $deals?: Awaited<ReturnType<typeof useFetch<Deal[]>>>
  $financialaccounts?: Awaited<ReturnType<typeof useFetch<Financialaccount[]>>>
  $users?: Awaited<ReturnType<typeof useFetch<User[]>>>
} = {}) => {
  const options = {
    ...optionsQuotes,
    delivery: optionsDeliveries,
    accountId: computed(() => $accounts?.data.value.map(toOption)),
    branchId: computed(() => $branches?.data.value.map(toOption)),
    contactId: computed(() => $contacts?.data.value.map(toOption)),
    dealId: computed(() => $deals?.data.value.map(toOption)),
    financialaccountId: computed(() => $financialaccounts?.data.value.map(toOption)),
    userId: computed(() => $users?.data.value.map(toOption)),
  }

  const maps = {
    type: toMapByKey(optionsQuotes.type, 'value'),
    status: toMapByKey(optionsQuotes.status, 'value'),
    satCfdiUse: toMapByKey(optionsSAT.taxCfdiUse, 'value'),
    billing: {
      cfdiType: toMapByKey(optionsSAT.cfdiType, 'value'),
      satFiscalRegime: toMapByKey(optionsSAT.fiscalRegime, 'value'),
      satTaxObject: toMapByKey(optionsSAT.taxObject, 'value'),
    },
    payment: {
      method: toMapByKey(optionsSAT.paymentMethod, 'value'),
      form: toMapByKey(optionsSAT.paymentForm, 'value'),
      condition: toMapByKey(optionsAccounts.billing.paymentConditions, 'value'),
      currency: toMapByKey(optionsSAT.currency, 'value'),
    },
    delivery: {
      incoterm: toMapByKey(optionsDeliveries.incoterm, 'value'),
      schedule: toMapByKey(optionsDeliveries.schedule, 'value'),
    },
    accountId: computed(() => toMapByKey($accounts?.data.value, 'id')),
    branchId: computed(() => toMapByKey($branches?.data.value, 'id')),
    contactId: computed(() => toMapByKey($contacts?.data.value, 'id')),
    dealId: computed(() => toMapByKey($deals?.data.value, 'id')),
    financialaccountId: computed(() => toMapByKey($financialaccounts?.data.value, 'id')),
    userId: computed(() => toMapByKey($users?.data.value, 'id')),
    dealsByAccountId: computed(() => toMapByGroupKey($deals?.data.value, 'accountId')),
    branchesByAccountId: computed(() => toMapByGroupKey($branches?.data.value, 'accountId')),
    contactsByAccountId: computed(() => toMapByGroupKey($contacts?.data.value, 'accountId')),
  }

  return { options, maps }
}
