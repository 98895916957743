<script setup lang="ts">
import type { BlobObject, BlobPutOptions } from '@nuxthub/core'

const model = defineModel<BlobObject | null>()

const props = defineProps<{
  tableName: TableName
  recordId: string
  type: string // e.g. 'filePdf', 'fileCsfPdf'
  filename?: string // (optional) filename e.g. 'csf.pdf' of fileCsfPdf slot
  accept?: string // comma-separated list of MIME types e.g. 'image/svg+xml, image/png'
  options?: BlobPutOptions
  data?: Partial<File>
}>()

const { tableName, recordId, type } = props

const { createRenamedFile, uploadFile, removeFile } = useApiBlob(tableName, recordId)

const id = useId()

const loading = ref(false)

const onChange = async (fileList: FileList) => {
  loading.value = true
  const file = fileList[0]!
  if (!file) return

  // DISABLED: do not enforce file maxSize limit
  // if (file.size > 1e6) return alert('El archivo excede el tamaño máximo permitido de 1MB')

  if (model.value) await removeFile(model.value) // remove existing (previous model value)
  model.value = await uploadFile(type, file, props.data, props.options) // manually set new model value
  document.getElementById(id).value = null
  loading.value = false
}

const onClickRemove = async () => {
  if (model.value) {
    await removeFile(model.value)
    model.value = undefined
    document.getElementById(id).value = null
  }
}
</script>

<template>
  <UInput
    :id="id"
    type="file"
    name="file"
    :accept="accept"
    @change="onChange"
  >
    <UProgress
      v-if="loading"
      animation="carousel"
      size="sm"
      class="px-1"
    />
    <div v-if="model?.pathname" class="w-full flex items-center mt-1">
      <ButtonFile
        :file="model"
        :on-remove="onClickRemove"
      />
    </div>
  </UInput>
</template>
