<script setup lang="ts">
import type { ZodObject } from 'zod'

const model = defineModel<Quoteitem | Salesorderitem>({ required: true })

const props = defineProps<{
  utils: QuoteitemsUtils
  schema?: ZodObject<unknown>
  disabledFields?: (keyof Quoteitem | string)[]
  disabled?: boolean
  disabledTabs?: ['transfers' | 'retentions']
}>()

const selectedTab = ref(0)

const tabs = computed(() => {
  // Calculate the total amount for transfers and retentions
  const transfersTotal = model.value.taxes.transfers.reduce((acc, tax) => acc + tax.amount, 0)
  const retentionsTotal = model.value.taxes.retentions.reduce((acc, tax) => acc + tax.amount, 0)

  return [
    {
      label: `Traslados (${toCurrency(transfersTotal, useAppConfig().netzo.defaults.currency)})`,
      disabled: props.disabledTabs?.includes('transfers') ?? false, // Check if "transfers" is in disabledTabs
    },
    {
      label: `Retenciones (${toCurrency(retentionsTotal, useAppConfig().netzo.defaults.currency)})`,
      disabled: props.disabledTabs?.includes('retentions') ?? false, // Check if "retentions" is in disabledTabs
    },
  ]
})
</script>

<template>
  <UFormGroup
    label="Objeto de Impuesto"
    name="fiscalData.satTaxObject"
    :required="isRequired(schema, 'fiscalData.satTaxObject')"
    class="md:col-span-4"
  >
    <SelectMenuBase
      v-model="model.fiscalData.satTaxObject"
      :options="optionsSAT.taxObject"
      :disabled="disabledFields?.includes('fiscalData.satTaxObject')"
    />
  </UFormGroup>

  <UFormGroup
    label="Unidad (SAT)"
    name="fiscalData.satUnitKey"
    :required="isRequired(schema, 'fiscalData.satUnitKey')"
  >
    <SelectMenuBase
      v-model="model.fiscalData.satUnitKey"
      :disabled="disabledFields?.includes('fiscalData.satUnitKey')"
      :options="optionsSAT.productUnitId"
    />
  </UFormGroup>

  <UFormGroup
    label="Clave Producto (SAT)"
    name="fiscalData.satProductServiceKey"
    :required="isRequired(schema, 'fiscalData.satProductServiceKey')"
    class="md:col-span-4"
  >
    <InputBaseLink
      v-model="model.fiscalData.satProductServiceKey"
      :disabled="disabledFields?.includes('fiscalData.satProductServiceKey')"
      href="https://www.sat.gob.mx/consultas/53693/catalogo-de-productos-y-servicios"
    />
  </UFormGroup>

  <AppAlert
    v-if="['01'].includes(model.fiscalData?.satTaxObject)"
    type="info"
    title="Item sin objecto de impuestos. Los impuestos estan deshabilitados y no se aplicarán."
    class="md:col-span-12"
  />

  <template v-else>
    <UTabs
      v-model="selectedTab"
      :items="tabs"
    />
    <div :class="FIELDSET">
      <UFormGroup
        v-if="selectedTab === 0"
        label="Traslados"
        name="transfers"
        class="flex flex-col gap-2"
      >
        <div class="grid grid-cols-[1fr,2fr,2fr,2fr] items-center gap-2">
          <UCheckbox
            v-model="model.taxes.transfers[0].enabled"
            label="IVA"
          />
          <SelectMenuBase
            v-model="model.taxes.transfers[0].satFactorType"
            :options="optionsSAT.taxFactor"
            :disabled="!model.taxes.transfers[0].enabled"
          />
          <SelectMenuBase
            v-model="model.taxes.transfers[0].satRateOrQuota"
            :options="optionsSAT.tax002RateTransfers"
            :disabled="!model.taxes.transfers[0].enabled"
          />
          <InputReadOnly
            :model-value="toCurrency(model.taxes.transfers[0]?.amount || 0, useAppConfig().netzo.defaults.currency)"
          />
        </div>
        <div class="grid grid-cols-[1fr,2fr,2fr,2fr] items-center gap-2 mt-2">
          <UCheckbox
            v-model="model.taxes.transfers[1].enabled"
            label="IEPS"
          />
          <SelectMenuBase
            v-model="model.taxes.transfers[1].satFactorType"
            :options="optionsSAT.taxFactor"
            :disabled="!model.taxes.transfers[1].enabled"
          />
          <SelectMenuBase
            v-model="model.taxes.transfers[1].satRateOrQuota"
            :options="optionsSAT.tax003RateTransfers"
            :disabled="!model.taxes.transfers[1].enabled"
          />
          <InputReadOnly
            :model-value="toCurrency(model.taxes.transfers[1]?.amount || 0, useAppConfig().netzo.defaults.currency)"
          />
        </div>
        <div class="grid grid-cols-[1fr,2fr,2fr,2fr] items-center gap-2 mt-2">
          <div class="col-span-2" />
          <div class="flex justify-end">
            <span class="font-regular">Total:</span>
          </div>

          <InputReadOnly
            :model-value="toCurrency(
              model.taxes.transfers.reduce((acc, tax) => acc + tax.amount, 0) || 0,
              useAppConfig().netzo.defaults.currency,
            )"
          />
        </div>
      </UFormGroup>
      <UFormGroup
        v-else
        label="Retenciones"
        name="retentions"
        class="flex flex-col gap-2"
      >
        <div class="grid grid-cols-[1fr,2fr,2fr,2fr] items-center gap-2">
          <UCheckbox
            v-model="model.taxes.retentions[0].enabled"
            label="IVA"
          />
          <SelectMenuBase
            v-model="model.taxes.retentions[0].satFactorType"
            :options="optionsSAT.taxFactor"
            :disabled="!model.taxes.retentions[0].enabled"
          />
          <SelectMenuBase
            v-model="model.taxes.retentions[0].satRateOrQuota"
            :options="optionsSAT.tax002RateRetentions"
            :disabled="!model.taxes.retentions[0].enabled"
          />
          <InputReadOnly
            :model-value="toCurrency(model.taxes.retentions[0]?.amount || 0, useAppConfig().netzo.defaults.currency)"
          />
        </div>
        <div class="grid grid-cols-[1fr,2fr,2fr,2fr] items-center gap-2 mt-2">
          <UCheckbox
            v-model="model.taxes.retentions[1].enabled"
            label="ISR"
          />
          <SelectMenuBase
            v-model="model.taxes.retentions[1].satFactorType"
            :options="optionsSAT.taxFactor"
            :disabled="!model.taxes.retentions[1].enabled"
          />
          <SelectMenuBase
            v-model="model.taxes.retentions[1].satRateOrQuota"
            :options="optionsSAT.tax001RateRetentions"
            :disabled="!model.taxes.retentions[1].enabled"
          />
          <InputReadOnly
            :model-value="toCurrency(model.taxes.retentions[1]?.amount || 0, useAppConfig().netzo.defaults.currency)"
          />
        </div>
        <div class="grid grid-cols-[1fr,2fr,2fr,2fr] items-center gap-2 mt-2">
          <div class="col-span-2" />
          <div class="flex justify-end">
            <span class="font-regular">Total:</span>
          </div>

          <InputReadOnly
            :model-value="toCurrency(
              model.taxes.retentions.reduce((acc, tax) => acc + tax.amount, 0) || 0,
              useAppConfig().netzo.defaults.currency,
            )"
          />
        </div>
      </UFormGroup>
    </div>
  </template>
</template>
