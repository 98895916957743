<script setup lang="ts">
import type { AccordionItem } from '#ui/types'
import { format, parseISO } from 'date-fns'

const props = defineProps<{
  data: Record<string, unknown>
  tableName: Auditlog['tableName']
  recordId: Auditlog['recordId']
}>()

const query = ref({
  action: [],
  tableName: [props.tableName],
  recordId: [props.recordId],
  userId: [],
  archivedAt: '$isNull',
  $search: { columns: ['text'], value: '' },
  $sort: { column: 'createdAt', direction: 'desc' },
  // $pagination: view.value.pagination,
  $with: { user: true },
})
const $auditlogs = await useFetch<Auditlog[]>('/api/db/auditlogs', {
  query,
  default: () => [],
})

const {
  getDropdownItems,
} = useAuditlogs()

const $users = await useFetch<User[]>('/api/db/users', {
  query: { $columns: ['id', 'name', 'image'] },
  default: () => [],
})
const utils = useAuditlogsUtils({ $users })

const state = ref<Data>(props.data)

const tabItems = computed(() => [
  { value: 'details', label: 'Detalles', disabled: false },
  { value: 'modifications', label: 'Modificaciones', disabled: false },
])

const selectedTab = ref<number>(0)

const items = computed<AccordionItem[]>(() => [
  {
    slot: 'general',
    label: 'Información general',
    icon: 'i-mdi-information',
    defaultOpen: true,
  },
])
</script>

<template>
  <UDashboardSlideover title="Información">
    <UTabs v-model="selectedTab" :items="tabItems" />

    <template v-if="selectedTab === 0">
      <UAccordion
        multiple
        :items="items"
        :ui="{
          wrapper: 'h-full overflow-y-auto',
          item: { base: 'grid grid-cols-2 gap-1 items-start text-xs p-4' },
          default: { class: 'rounded-none bg-transparent border-t border-gray-200 dark:border-gray-800' },
        }"
      >
        <template #general>
          <ViewCardSlot label="Id">
            {{ state.id }}
          </ViewCardSlot>

          <ViewCardSlot v-if="state.name" label="Nombre">
            {{ state.name }}
          </ViewCardSlot>

          <ViewCardSlot v-if="state.uid" label="Referencia">
            {{ state.uid }}
          </ViewCardSlot>

          <ViewCardSlot v-if="state.createdBy" label="Creado por">
            <ViewRendererReference :row="utils.maps.userId!.value.get(state.createdBy)" hide-avatar />
          </ViewCardSlot>

          <ViewCardSlot v-if="state.createdAt" label="Fecha de creación">
            {{ format(parseISO(state.createdAt), 'yyyy-MM-dd HH:mm:ss') }}
          </ViewCardSlot>

          <ViewCardSlot v-if="state.updatedBy" label="Actualizado por">
            <ViewRendererReference :row="utils.maps.userId!.value.get(state.updatedBy)" hide-avatar />
          </ViewCardSlot>

          <ViewCardSlot v-if="state.updatedAt" label="Fecha de actualización">
            {{ format(parseISO(state.updatedAt), 'yyyy-MM-dd HH:mm:ss') }}
          </ViewCardSlot>

          <ViewCardSlot v-if="state.archivedBy" label="Archivado por">
            <ViewRendererReference :row="utils.maps.userId!.value.get(state.archivedBy)" hide-avatar />
          </ViewCardSlot>

          <ViewCardSlot v-if="state.archivedAt" label="Fecha de archivado">
            {{ format(parseISO(state.archivedAt), 'yyyy-MM-dd HH:mm:ss') }}
          </ViewCardSlot>
        </template>
      </UAccordion>
    </template>

    <template v-else-if="selectedTab === 1">
      <ViewPlaceholder
        :loading="['pending'].includes($auditlogs.status.value)"
        :data="$auditlogs.data.value"
        :icon="ICONS?.auditlogs"
        label="No hay registros de auditoría para mostrar."
      >
        <div class="flex-1 h-full overflow-y-auto flex flex-col gap-3">
          <AppAuditlogsCardAuditlog
            v-for="auditlog in $auditlogs.data.value"
            :key="`auditlog-${auditlog.id}`"
            :auditlog="auditlog"
            :utils="utils"
            :dropdown-items="getDropdownItems(auditlog, $auditlogs.refresh, utils)"
          />
        </div>
      </ViewPlaceholder>
    </template>
  </UDashboardSlideover>
</template>
