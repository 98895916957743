import type { HookContext, NextFunction } from '@feathersjs/hooks'
import type { BlobObject } from '@nuxthub/core'
import type { TData } from '../../useApiDB'

export const handleErrors = (successMessage: string, errorMessage: string) => {
  return async (context: HookContext, next: NextFunction) => {
    const [_, toast = true] = context.arguments as [unknown, boolean]
    try {
      await next()
      if (toast) useToastAlert().success(successMessage)
    }
    catch (error) {
      if (toast) useToastAlert().error(errorMessage)
      console.error(error)
    }
  }
}

const tablesWithItems = [
  'pricelists',
  'productionorders',
  'products',
  'quotes',
  'salesorders',
  'services',
] as const

const cascadeUpdates = async (tableName: TableName, row: TData) => {
  // skip unecessary updates to items if not editing certain fields
  if (!['immutable', 'archivedAt', 'archivedBy'].some(col => col in row)) return

  const prefix = tableName.replace(/s$/, '')
  const tableNameItems = `${prefix}items`
  const idField = `${prefix}Id`
  const items = await $fetch(`/api/db/${tableNameItems}?${idField}=${row.id}`)
  await Promise.all(items.map(async (item) => {
    return $fetch(`/api/db/${tableNameItems}/${item.id}`, {
      method: 'PATCH',
      body: {
        id: item.id,
        ...('immutable' in row && { immutable: row.immutable }),
        ...('archivedAt' in row && { archivedAt: row.archivedAt }),
        ...('archivedBy' in row && { archivedBy: row.archivedBy }),
      },
    })
  }))
}

export const handleCascadeUpdates = (tableName: TableName) => {
  return async (context: HookContext, next: NextFunction) => {
    const [row] = context.arguments as [TData]

    await next()

    if (row && tablesWithItems.includes(tableName)) {
      await cascadeUpdates(tableName, row)
    }
  }
}

export const handleCascadeUpdatesMultiple = (tableName: TableName) => {
  return async (context: HookContext, next: NextFunction) => {
    const [rows] = context.arguments as [TData[]]

    await next()

    if (rows && tablesWithItems.includes(tableName)) {
      await Promise.all(rows.map(row => cascadeUpdates(tableName, row)))
    }
  }
}

export const handleCreate = (tableName: TableName) => {
  return async (context: HookContext, next: NextFunction) => {
    const [row] = context.arguments as [TData]

    await next()

    if (['productionorders'].includes(tableName)) {
      // create productionorderitems from salesorderitems with same salesorderId
      if (!row.salesorderId) return
      const salesorderitems = await $fetch<Salesorderitem[]>(`/api/db/salesorderitems`, {
        query: { type: ['products'], salesorderId: row.salesorderId },
      })
      await Promise.all(salesorderitems.map((salesorderitem: Salesorderitem) => {
        return $fetch(`/api/db/productionorderitems`, {
          method: 'POST',
          body: getDefaultProductionorderitem({
            type: salesorderitem.subtype,
            name: salesorderitem.name,
            images: salesorderitem.images,
            sku: salesorderitem.sku,
            unit: salesorderitem.unit,
            attributes: salesorderitem.attributes,
            quantityPlanned: salesorderitem.quantity,
            text: salesorderitem.description,
            files: salesorderitem.files,
            productitemId: salesorderitem.productitemId,
            productionorderId: row.id,
            salesorderitemId: salesorderitem.id,
            userId: row.userId,
          }),
        })
      }))
    }
    if (['salesorders'].includes(tableName)) {
      // create salesorderitems from quoteitems with same quoteId
      const quoteitems = await $fetch<QuoteitemWithRelations[]>(`/api/db/quoteitems`, {
        query: { quoteId: row.quoteId, $with: { quote: true } },
      })
      await Promise.all(quoteitems.map((quoteitem) => {
        if (quoteitem.quote?.type !== 'totalized') return
        return $fetch(`/api/db/salesorderitems`, {
          method: 'POST',
          body: getDefaultSalesorderitem({
            type: quoteitem.type,
            subtype: quoteitem.subtype,
            name: quoteitem.name,
            description: quoteitem.description,
            images: quoteitem.images,
            sku: quoteitem.sku,
            unit: quoteitem.unit,
            quantity: quoteitem.quantity,
            unitPrice: quoteitem.unitPrice,
            discount: quoteitem.discount,
            fiscalData: quoteitem.fiscalData,
            taxes: quoteitem.taxes,
            amount: quoteitem.amount,
            total: quoteitem.total,
            productitemId: quoteitem.productitemId,
            quoteitemId: quoteitem.id,
            serviceitemId: quoteitem.serviceitemId,
            salesorderId: row.id,
          }),
        })
      }))
    }
  }
}

export const handleUpdate = (tableName: TableName) => {
  return async (context: HookContext, next: NextFunction) => {
    const [row] = context.arguments as [TData]

    await next()
  }
}

export const handleDelete = (tableName: TableName) => {
  return async (context: HookContext, next: NextFunction) => {
    const [rows] = context.arguments as [TData[]]

    if (['pricelists'].includes(tableName)) {
      await Promise.all(rows.map((row) => {
        const { removeFile } = useApiBlob(tableName, row.id!)
        return removeFile(row.filePdf as BlobObject)
      }))
    }
    if (['productionorders'].includes(tableName)) {
      await Promise.all(rows.map((row) => {
        const { removeFile } = useApiBlob(tableName, row.id!)
        return removeFile(row.filePdf as BlobObject)
      }))
    }
    if (['quotes'].includes(tableName)) {
      await Promise.all(rows.map((row) => {
        const { removeFile } = useApiBlob(tableName, row.id!)
        return removeFile(row.filePdf as BlobObject)
      }))
    }
    if (['salesorders'].includes(tableName)) {
      await Promise.all(rows.map((row) => {
        const { removeFile } = useApiBlob(tableName, row.id!)
        return removeFile(row.filePdf as BlobObject)
      }))
    }
    if (['installationorders'].includes(tableName)) {
      await Promise.all(rows.map((row) => {
        const { removeFile } = useApiBlob(tableName, row.id!)
        return removeFile(row.filePdf as BlobObject)
      }))
    }

    await next()
  }
}
