<script setup lang="ts">
import type { ZodSchema } from 'zod'

const model = defineModel<Quote | Salesorder>({ required: true })

const props = defineProps<{
  disabledFields?: (keyof Delivery)[]
  disabled?: boolean
  schema: ZodSchema
  utils: QuotesUtils | SalesordersUtils
  refresh: () => Promise<void>
  openNestedCallback: (data: Partial<Salesorder>) => void
}>()

const selectedTab = ref(model.value.delivery!.useAccountAddress ? 0 : 1)

watch(() => model.value.delivery.useAccountAddress, () => {
  selectedTab.value = model.value.delivery.useAccountAddress ? 0 : 1
})

const tabs = [
  { label: 'Domicilio fiscal', icon: ICONS.accounts },
  { label: 'Sucursal', icon: ICONS.branches },
]

const onUpdateDeliveryAddress = () => {
  if (model.value.delivery!.useAccountAddress) {
    const account = props.utils.maps.accountId.value.get(model.value.accountId)
    if (account) {
      model.value.delivery!.address = account.billing?.address ?? {} as Address
    }
  }
  else if (model.value.branchId) {
    model.value.delivery!.address = props.utils.maps.branchId.value.get(model.value.branchId)?.address ?? {} as Address
  }
}

const onUpdateTab = (tab: number) => {
  if (tab === 0) { model.value.delivery!.useAccountAddress = true }
  else { model.value.delivery!.useAccountAddress = false }
  onUpdateDeliveryAddress()
}

const onUpdateDeliverySchedule = (schedule: string) => {
  if (['scheduled'].includes(schedule)) {
    model.value.delivery!.isDatetime = false
    model.value.delivery!.date = ''
  }
}

const onUpdateBranchId = async (branchId: string) => {
  const branch = props.utils.maps.branchId.value.get(branchId)
  model.value.delivery!.address = branch?.address ?? ({} as Address)
}

watch(() => model.value.delivery.incoterm, () => {
  if (['pending', 'EXW'].includes(model.value.delivery.incoterm)) {
    model.value.delivery.address = {} as Address
  }
  else {
    onUpdateDeliveryAddress()
  }
})

const FIELDSET = `w-full grid gap-2`
</script>

<template>
  <fieldset :class="FIELDSET" :disabled="disabled">
    <UFormGroup
      label="Términos de entrega"
      name="delivery.incoterm"
      :required="isRequired(schema, 'delivery.incoterm')"
    >
      <SelectMenuBaseInfo
        v-model="model.delivery.incoterm"
        :options="optionsDeliveries.incoterm"
        :disabled="disabledFields?.includes('delivery.incoterm')"
      />
    </UFormGroup>

    <UFormGroup
      label="Tiempo de entrega"
      name="delivery.schedule"
      :required="isRequired(schema, 'delivery.schedule')"
    >
      <SelectMenuBaseInfo
        v-model="model.delivery.schedule"
        :options="optionsDeliveries.schedule"
        :disabled="disabledFields?.includes('delivery.schedule')"
        @update:model-value="onUpdateDeliverySchedule"
      />
    </UFormGroup>

    <UFormGroup
      v-if="['scheduled'].includes(model.delivery?.schedule)"
      label="Fecha de entrega"
      name="delivery.date"
      :required="isRequired(schema, 'delivery.date')"
    >
      <InputDatetime
        v-model:date="model.delivery.date"
        v-model:is-datetime="model.delivery.isDatetime"
        :disabled="disabledFields?.includes('delivery.date')"
      />
    </UFormGroup>

    <UFormGroup
      label="Detalles entrega"
      name="delivery.text"
      :required="isRequired(schema, 'delivery.text')"
    >
      <UTextarea
        v-model="model.delivery.text"
        autoresize
        :disabled="disabledFields?.includes('delivery.text')"
      />
    </UFormGroup>

    <UFormGroup
      v-if="!['pending', 'EXW'].includes(model.delivery?.incoterm)"
      label="Dirección de entrega"
      name="branchId"
      :required="model.delivery.useAccountAddress && !['EXW', 'pending'].includes(model.delivery?.incoterm)"
    >
      <UTabs
        v-model="selectedTab"
        :items="tabs"
        @update:model-value="onUpdateTab"
      />
      <SelectMenuBaseReference
        v-if="!model.delivery.useAccountAddress"
        v-model="model"
        v-bind="{
          tableName: 'branches',
          idField: 'branchId',
          options: utils.maps.branchesByAccountId.value.get(model.accountId)?.map(toOption) ?? [],
          disabled: disabledFields?.includes('branchId') || !model.accountId,
          onClickUpdate: onUpdateBranchId,
          slideoverOpenCreateProps: {
            data: { accountId: model.accountId, contactId: model.contactId },
            disabledFields: ['accountId'],
          },
          refresh,
          openNestedCallback: (data) => openNestedCallback({ ...model, data }).then(refresh),
        }"
      />

      <FieldsetAddress
        v-if="!['pending', 'EXW'].includes(model.delivery?.incoterm)"
        v-model="model.delivery.address"
        v-model:name="model.name"
        disabled
        v-bind="{ schema, path: 'delivery.address', disabledFields }"
      />
    </UFormGroup>

    <AppAlert
      v-if="!['pending', 'EXW'].includes(model.delivery?.incoterm)"
      type="warning"
      description="Para actualizar la dirección es necesario editar la sucursal o el domicilio fiscal."
    />
  </fieldset>
</template>
