<script setup lang="ts">
import type { AccordionItem, FormSubmitEvent } from '#ui/types'
import { merge } from 'es-toolkit/compat'

const props = defineProps<{
  action: 'create' | 'edit'
  data: Partial<Quoteitem>
  onSubmit: (event: FormSubmitEvent<Partial<Quoteitem>>) => void
  openNestedCallback: (data: Partial<Quoteitem>) => void
  title?: string
  disabledFields?: (keyof Quoteitem | string)[]
  readonly?: boolean
}>()

const { user } = useUserSession()
const { userModule } = useModules()
const slideover = useSlideover()

const inert = computed(() => props.readonly || props.data?.immutable || !['admin', 'edit'].includes(userModule.value?.role))
const simple = ref(true)

const state = ref<Partial<Quoteitem>>(getDefaultQuoteitem(props.data))

const $pricelists = await useFetch<PricelistWithRelations[]>('/api/db/pricelists', {
  query: {
    status: ['completed'],
    $with: relationsPricelists,
    $sort: { column: 'date', direction: 'desc' },
  },
  default: () => [],
})
const $productitems = await useFetch<Productitem[]>('/api/db/productitems', {
  query: { $with: { product: true } },
  default: () => [],
})
const $serviceitems = await useFetch<Serviceitem[]>('/api/db/serviceitems', {
  query: { $with: { service: true } },
  default: () => [],
})

const utils = useQuoteitemsUtils({ $pricelists, $productitems, $serviceitems })

const items = computed<AccordionItem[]>(() => {
  if (['create'].includes(props.action) && simple.value) {
    return [{ slot: 'simple', label: 'Creación rápida', icon: 'i-mdi-lightning-bolt', defaultOpen: true }]
  }
  return [
    {
      slot: 'general',
      label: 'General',
      icon: 'i-mdi-information',
      defaultOpen: true,
    },
    {
      slot: 'details',
      label: 'Detalles',
      icon: ICONS.quoteitems,
      defaultOpen: true,
    },
    {
      slot: 'taxes',
      label: 'Información fiscal',
      icon: 'i-mdi-scale-balance',
      defaultOpen: true,
    },
    {
      slot: 'data',
      label: 'Datos adicionales',
      icon: 'i-mdi-code-json',
      defaultOpen: false,
    },
  ].filter(item => !['data'].includes(item.slot) || state.value.data)
})

const parentLabel = computed(() => state.value.type === 'products' ? 'Producto' : 'Servicio')
const itemTable = computed(() => state.value.type === 'products' ? 'productitems' : 'serviceitems')
const itemIdField = computed(() => state.value.type === 'products' ? 'productitemId' : 'serviceitemId')
const itemToConcept = computed(() => state.value.type === 'products' ? productitemToConcept : serviceitemToConcept)

const onUpdateType = (value: string) => {
  state.value = getDefaultQuoteitem(props.data)
  state.value.type = value
  if (value === 'products') {
    state.value.subtype = 'finished-goods'
  }
  else {
    state.value.subtype = 'core'
  }
}

const onUpdateItem = (value: string) => {
  const item = utils.maps[itemIdField.value]?.value.get(value) as (Productitem | Serviceitem) & { product?: Product, service?: Service }
  const pricelists = utils.maps.pricelistsByType?.value.get(state.value?.type) ?? [] as PricelistWithRelations[]
  const basePrice = getPriceForItem(pricelists, state.value?.type, item.id)
  state.value = merge(state.value, itemToConcept.value(item, basePrice))
}
watch(() => state.value.fiscalData?.satTaxObject, (satTaxObject) => {
  const disable = ({ enabled, ...rest }) => ({ enabled: false, ...rest })
  // disable all taxes if satTaxObject is "01 - Sin objeto de impuestos"
  if (['01'].includes(satTaxObject)) {
    state.value.taxes.transfers = state.value.taxes.transfers.map(disable)
    state.value.taxes.retentions = state.value.taxes.retentions.map(disable)
  }
})

const onUpdateQuoteitemTaxes = () => {
  const calculateTax = (taxItem: ItemTaxes) => ({
    ...taxItem,
    base: Number(state.value.amount),
    amount: taxItem.enabled ? Number(state.value.amount) * Number(taxItem.satRateOrQuota) : 0,
  })

  const { transfers, retentions } = state.value.taxes!

  state.value.taxes.transfers = transfers.map(calculateTax)
  state.value.taxes.retentions = retentions.map(calculateTax)
}

watchDebounced([
  () => state.value.quantity,
  () => state.value.unitPrice,
  () => state.value.discount.value,
  () => state.value.discount.type,
  () => state.value.discount.amount,
  () => state.value.taxes.transfers,
  () => state.value.taxes.retentions,
], () => {
  const { quantity = 0, unitPrice = 0, discount } = state.value
  const amount = quantity * unitPrice || 0
  const discountAmount = discount!.type! === 'percent'
    ? (amount * (discount.value ?? 0))
    : (discount.value ?? 0)
  state.value.discount = { ...discount, amount: discountAmount }
  state.value.amount = amount - discountAmount
  onUpdateQuoteitemTaxes()
}, { debounce: 50 })

const loading = ref(false)

const onSubmitQuoteitem = async (event: FormSubmitEvent<Partial<Quoteitem>>) => {
  event.data = merge(state.value, event.data) // WORKAROUND: UForm drops nested props on validation
  loading.value = true
  event.data.total = event.data.amount + event.data.taxes.transfers
    .reduce((acc, tax) => acc + tax.amount, 0) - event.data.taxes.retentions
    .reduce((acc, tax) => acc + tax.amount, 0)
  await props.onSubmit(event)
}

const quoteitems$ = useQuoteitems()

const schema = computed(() => ['products'].includes(state.value.type)
  ? quoteitems$.schemaProducts
  : quoteitems$.schemaServices,
)
</script>

<template>
  <UDashboardSlideover prevent-close :ui="{ width: 'min-w-[40vw]' }">
    <template #title>
      <SlideoverTitle :title="title" :inert="inert" />
    </template>
    <UForm
      id="form.quoteitems"
      :validate-on="['submit']"
      :schema="schema"
      :state="state"
      :inert="inert"
      @error="onFormError"
      @submit="onSubmitQuoteitem"
    >
      <UAccordion
        multiple
        :items="items"
        :ui="{ item: { base: 'py-1 px-2 space-y-2 text-sm' } }"
      >
        <template #simple>
          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              autofocus
              :disabled="disabledFields?.includes('type')"
              @update:model-value="onUpdateType"
            />
          </UFormGroup>

          <UFormGroup
            :label="`Tipo de ${parentLabel}`"
            name="subtype"
            :required="isRequired(schema, 'subtype')"
          >
            <SelectMenuBaseInfo
              v-if="state.type === 'products'"
              v-model="state.subtype"
              :options="optionsProducts.type"
              disabled
            />
            <SelectMenuBaseInfo
              v-else
              v-model="state.subtype"
              :options="optionsServices.type"
              :disabled="disabledFields?.includes('subtype')"
            />
          </UFormGroup>

          <UFormGroup
            :label="`Item`"
            :name="itemIdField"
            :required="isRequired(schema, itemIdField)"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: itemTable,
                idField: itemIdField,
                utils: utils,
                options: utils.maps[ state.type === 'products' ? 'productitemsByProductType' : 'serviceitemsByServiceType']?.value.get(state.subtype)?.map(toOption) ?? [],
                map: utils.maps[itemIdField],
                disabled: [itemIdField].includes(disabledFields),
                onClickUpdate: onUpdateItem,
              }"
            />
          </UFormGroup>

          <div class="grid md:grid-cols-2 gap-2">
            <UFormGroup
              label="Cantidad"
              name="quantity"
              :required="isRequired(schema, 'quantity')"
            >
              <UInput
                v-model="state.quantity"
                type="number"
                inputmode="decimal"
                :min="0.00"
                :step="0.01"
                :disabled="disabledFields?.includes('quantity')"
              />
            </UFormGroup>

            <UFormGroup
              label="Unidad"
              name="unit"
              :required="isRequired(schema, 'unit')"
            >
              <SelectMenuBase
                v-model.defaultcase="state.unit"
                :disabled="disabledFields?.includes('unit')"
                :options="utils.options.unit"
              />
            </UFormGroup>

            <UFormGroup
              :label="`Precio U (${useAppConfig().netzo.defaults.currency})`"
              name="unitPrice"
              :required="isRequired(schema, 'unitPrice')"
            >
              <InputCurrency
                v-model="state.unitPrice"
                :currency="useAppConfig().netzo.defaults.currency"
                icon
                :disabled="disabledFields?.includes('unitPrice')"
              />
            </UFormGroup>

            <UFormGroup
              :label="`Subtotal (${useAppConfig().netzo.defaults.currency})`"
              name="amount"
              :required="isRequired(schema, 'amount')"
            >
              <InputReadOnly
                :model-value="toCurrency(isNaN(state.amount) ? 0 : (state.amount ?? 0), useAppConfig().netzo.defaults.currency)"
              />
            </UFormGroup>
          </div>

          <SlideoverButtonExpand @click="simple = false" />
        </template>

        <template #general>
          <UFormGroup
            label="Tipo"
            name="type"
            :required="isRequired(schema, 'type')"
          >
            <SelectMenuBaseInfo
              v-model="state.type"
              :options="utils.options.type"
              autofocus
              :disabled="disabledFields?.includes('type')"
              @update:model-value="onUpdateType"
            />
          </UFormGroup>

          <UFormGroup
            :label="`Tipo de ${parentLabel}`"
            name="subtype"
            :required="isRequired(schema, 'subtype')"
          >
            <SelectMenuBaseInfo
              v-if="state.type === 'products'"
              v-model="state.subtype"
              :options="optionsProducts.type"
              disabled
            />
            <SelectMenuBaseInfo
              v-else
              v-model="state.subtype"
              :options="optionsServices.type"
              :disabled="disabledFields?.includes('subtype')"
            />
          </UFormGroup>

          <UFormGroup
            :label="`Item`"
            :name="itemIdField"
            :required="isRequired(schema, itemIdField)"
          >
            <SelectMenuBaseReference
              v-model="state"
              clearable
              v-bind="{
                tableName: itemTable,
                idField: itemIdField,
                utils: utils,
                options: utils.maps[ state.type === 'products' ? 'productitemsByProductType' : 'serviceitemsByServiceType']?.value.get(state.subtype)?.map(toOption) ?? [],
                map: utils.maps[itemIdField],
                disabled: [itemIdField].includes(disabledFields),
                onClickUpdate: onUpdateItem,
              }"
            />
          </UFormGroup>
        </template>

        <template #details>
          <FieldsetConcept
            v-model="state"
            table-name="quoteitems"
            :disabled="disabledFields?.includes('concepts')"
            v-bind="{ utils, schema: schema, disabledFields }"
          />
        </template>

        <template #taxes>
          <FieldsetTaxesMX
            v-model="state"
            :disabled="disabledFields?.includes('taxes')"
            :disabled-tabs="['retentions']"
            v-bind="{ utils, schema: schema, disabledFields }"
          />
        </template>

        <template #data>
          <FieldsetData v-model="state.data" :utils="utils" />
        </template>
      </UAccordion>
    </UForm>

    <template v-if="!inert" #footer>
      <div class="grid grid-cols-2 gap-2 w-full">
        <UButton
          color="gray"
          label="Cancelar"
          block
          @click="slideover.close()"
        />
        <UButton
          form="form.quoteitems"
          type="submit"
          label="Confirmar"
          color="primary"
          block
          :loading="loading"
        />
      </div>
    </template>
  </UDashboardSlideover>
</template>
